.text {
    // .text__wrap
    &__wrap {
        @include x-container();
        margin: 70px auto 50px;
    }
    // .text__h1
    &__h1 {
        @extend .head__title;
        margin-bottom: 30px;
        text-align: center;
    }
    // .text__text
    &__text {
        @extend .text;
        @extend .admin;
    }
}
