.marketplace {
    // .marketplace__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .marketplace__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        font-weight: 500;
    }
    // .marketplace__body
    &__body {
    }
    // .marketplace__row
    &__row {
        display: flex;
        flex-direction: column;
    }
    // .marketplace__item
    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 35px;
        @media (min-width: 768px) {
            flex-direction: row;
            margin-bottom: 40px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 35px;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .marketplace__name
    &__name {
        @extend .text;
        margin-bottom: 10px;
        word-wrap: break-word;
        @media (min-width: 768px) {
            flex: 0 0 175px;
            margin: 0px 35px 0px 0px;
        }
    }
    // .marketplace__link
    &__link {
        @extend .text;
        color: $color-gray;
        text-decoration: underline;
        margin-bottom: 10px;
        word-wrap: break-word;
        @media (min-width: 768px) {
            margin: 0px 0px 0px 0px;
        }
    }
}
