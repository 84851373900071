.anketa {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #fff;
    // .anketa__wrap
    &__wrap {
        @include x-container(1160px);
        margin: 0 auto;
        text-align: center;
        overflow: hidden
    }
    // .anketa__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
        @media (min-width: 1200px) {
            margin-bottom: 50px;
        }
    }
    // .anketa__text
    &__text {
        @extend .text;
        margin-bottom: 30px;
        text-align: center;
        color: #606060;
        @media (min-width: 768px) {
            margin-bottom: 50px;
            text-align: left;
        }
    }
    // .anketa__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -25px 20px;
    }
    // .anketa__column
    &__column {
        flex: 1 0 50%;
        margin-bottom: 30px;
        text-align: center;
        padding: 0 25px;
        color: $color-blue;
        transition: all 0.3s ease-in;
        @media (min-width: 768px) {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &:hover {
            color: red;
        }
    }
    // .anketa__icon
    &__icon {
        color: inherit;
        img {
            height: 70px;
        }
        @media(max-width:767px) {
            margin-bottom: 10px;
        }
        @media (min-width: 768px) {
            margin-right: 25px;
        }
    }
    // .anketa__name
    &__name {
        @extend .text;
        font-weight: 700;
        color: inherit;
    }
    // .anketa__btn
    &__btn {
        @extend .btn;
    }
}
