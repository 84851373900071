.vacancy {
    // .vacancy__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-bottom: 100px;
        @media (min-width: 768px) {
            padding-bottom: 80px;
        }
    }
    &__content {
        display: flex;
        @media (min-width: 1140px) {
            max-width: 870px;
            margin-right: auto;
        }
        @media (max-width: 1139px) {
            flex-direction: column;
        }
    }
    // .vacancy__list
    &__actual {
        @media (min-width: 1140px) {
            max-width: 275px;
            margin-right: auto;
        }
    }
    // .vacancy__title
    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 35px;
        }
    }
    // .vacancy__btn-box
    &__btn-box {
        @media (max-width: 1139px) {
            margin-bottom: 40px;
            display: flex;
        }
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    // .vacancy__btn
    &__btn {
        @extend .btn;
        width: 100%;
        text-align: center;
        @media (min-width: 1140px) {
            margin-bottom: 20px;
        }
        @media (max-width: 1139px) {
            margin-right: 20px;
        }
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 20px;
        }
        &:not(&_active) {
            color: $color-gray;
            background-color: transparent;
            border: 1px solid $color-gray;
        }
        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }
    }
    // .vacancy__description-box
    &__description-box {
        @media (min-width: 1140px) {
            flex: 0 0 475px;
        }
    }
    // .vacancy__description
    &__description {
        display: none;
        &_active {
            display: block;
        }
    }
    // .vacancy__subtitle
    &__subtitle {
        font-size: 18px;
        line-height: 22 / 18 * 100%;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            font-size: 22px;
            line-height: 27 / 22 * 100%;
            margin-bottom: 50px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 30px;
        }
    }
    // .vacancy__conditions
    &__conditions {
        margin-bottom: 30px;
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
    }
    // .vacancy__sub2title
    &__sub2title {
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: 1px solid #979797;
        margin-bottom: 20px;
    }
    // .vacancy__list
    &__list {
        margin-bottom: 25px;
    }
    // .vacancy__item
    &__item {
        @extend .item;
        font-size: 14px;
    }
    // .vacancy__send
    &__send {
        @extend .btn;
        background-color: transparent;
        border: 1px solid $color-gray;
        color: $color-gray;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: $color-gray;
            color: $color-white;
        }
        @media (max-width: 1139px) {
            width: 50%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}
