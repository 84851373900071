.content-info {
    // .content-info__content
    &__content {
        position: relative;
        @media (min-width: 768px) {
            padding: 0px 0px 20px 0px;
        }
    }
    // .content-info__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        display: flex;
    }
    // .content-info__body
    &__body {
        position: relative;
        width: 100%;
        padding: 45px 35px 50px;
        text-align: center;
        background-color: $color-white;
        z-index: 2;
        @media (min-width: 768px) {
            width: 50%;
        }
    }
    // .content-info__title
    &__title {
        font-family: $font-family_Lato;
        font-size: 22px;
        line-height: 31 / 32 * 100%;
        margin-bottom: 30px;
        .content-info__logo {
            display: block;
            text-align: center;
            img {
                width: 170px;
            }
        }
        @media (min-width: 768px) {
            font-size: 32px;
        }
    }
    // .content-info__text
    &__text {
        font-family: $font-family_Montserrat;
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        &_small {
            font-size: 14px;
        }
        margin-bottom: 25px;
    }
    // .content-info__link
    &__link {
        font-family: $font-family_Lato;
        font-size: 16px;
        line-height: 20 / 16 * 100%;

        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }

        // .content-info__link_mail
        &_mail {
            color: $color-gray;
        }
    }
    // .content-info__bg
    &__bg {
        display: none;
        @media(min-width:768px) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
