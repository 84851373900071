.tabs {
    // .tabs__wrap
    &__wrap {
        margin: 0 auto;
        @media (min-width: 768px) {
            padding: 0 15px;
        }
    }
    // .tabs__container
    &__container {
        max-width: 670px;
        margin: 0 auto;
    }
    // .tabs__btn-box
    &__btn-box {
        margin-bottom: 35px;
        display: flex;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
        @media (min-width: 768px) {
            justify-content: space-between;
            margin-bottom: 45px;
        }
    }
    // .tabs__btn
    &__btn {
        font-size: 14px;
        line-height: 180%;
        font-weight: 700;
        color: $color-black;
        text-decoration: underline dashed 2px $color-gray;
        text-underline-position: from-font;
        transition: all 0.3s ease 0s;
        &:hover {
            color: $color-gray;
            text-decoration: underline solid 2px $color-gray;
        }
        @media (min-width: 768px) {
            line-height: 17 / 14 * 100%;
        }
        @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    // .tabs__tab-box
    &__tab-box {
    }
    // .tabs__body
    &__body {
        display: none;
    }
    // .tabs__repair-conditions
    &__repair-conditions {
    }
    // .tabs__service-center
    &__service-center {
    }
}
