.b-service {
    background-color: $color-gray-dark;
    padding: 45px 0;
    text-align: center;
    font-size: 20px;
    font-family: $font-family_Lato;
    color: $color-white;
    @media(min-width:768px) {
        padding: 60px 0 40px;
    }
    // .b-service__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .b-service__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @media(min-width:768px) {
            justify-content: space-between;
        }
    }
    // .b-service__item
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        text-align: left;
        svg {
            flex: 0 0 40px;
            margin-right: 32px;
        }
        @media(max-width:767px) {
            // justify-content: center;
            flex: 1 1 calc(100% / 3);
        }
    }
    a.b-service__item {
        span {
            white-space: nowrap;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    // .b-service__emails
    &__emails {
        display: flex;
        flex-direction: column;
    }
    // .b-service__link
    &__link {
        &:hover {
            text-decoration: underline;
        }
    }
}
