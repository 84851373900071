.partner-info {
    position: relative;
    padding: 25px 0 30px;
    background-color: $color-gray;
    @media (min-width: 1200px) {
        padding: 35px 0 45px;
    }
    // .partner-info__wrap
    &__wrap {
        @include x-container;
        margin: 0 auto;
        text-align: center;
        @media (min-width: 1200px) {
            display: flex;
            text-align: left;
        }
    }
    // .partner-info__text
    &__text {
        margin-bottom: 25px;
        font-family: $font-family_Montserrat;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        font-weight: 500;
        color: $color-white;
        text-align: center;
        @media (min-width: 1200px) {
            flex: 1 1 auto;
            margin: 0px 30px 0px 0px;
        }
    }
    // .partner-info__btn
    &__btn {
        @include btn($color-white, $color-blue-dark);
        border-color: $color-white;

        &:hover {
            color: $color-white;
            border-color: $color-white;
        }

        @media (min-width: 1200px) {
            flex: 0 0 235px;
        }
    }
}
