.en-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 15;
    box-shadow: 0px 0px 5px 0px rgba($color: $color-black, $alpha: 0.7);
    // .en-header__wrap
    &__wrap {
        @include x-container();
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 95px;
        margin: 0 auto;
    }
    &._show {
        position: fixed;
        .en-header__nav {
            display: block;
            @include x-container();
            position: absolute;
            top: 95px;
            left: 0;
            width: 100%;
            height: 100vh;
            padding-top: 60px;
            padding-bottom: 60px;
            background-color: $color-white;
            z-index: 20;
            overflow-y: auto;
            ul {
                display: flex;
                align-items: center;
                flex-direction: column;
                li {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .en-header__burger {
            span {
                width: 0px;

                &::before {
                    background-color: $color-black;
                    transform: rotate(-45deg);
                    top: 0;
                }

                &::after {
                    background-color: $color-black;
                    transform: rotate(45deg);
                    top: 0;
                }
            }
        }
    }
    @media (max-width: 1023px) {
        justify-content: space-between;
    }
    // .en-header__logo
    &__logo {
        flex: 0 0 175px;
        margin-right: 50px;
        @media(min-width:1440px) {
            margin-right: 115px;
        }
    }
    // .en-header__burger
    &__burger {
        display: none;
        position: relative;
        // top: 30px;
        // right: 0;
        width: 30px;
        height: 30px;
        z-index: 15;

        span {
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $color-black;
            transition: all 0.3s ease-in;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 30px;
                height: 3px;
                background-color: $color-black;
                transition: all 0.3s ease 0s;
            }

            &::after {
                content: "";
                position: absolute;
                top: -10px;
                width: 30px;
                height: 3px;
                background-color: $color-black;
                transition: all 0.3s ease 0s;
            }
        }
        @media (max-width: 1023px) {
            display: block;
            z-index: 10;
        }
    }
    // .en-header__nav
    &__nav {
        width: 100%;
        @media(max-width:1023px) {
            display: none;
        }
    }
    // .en-header__list
    &__list {
        display: flex;
        justify-content: space-between;
        margin: 0 -5px;
    }
    // .en-header__item
    &__item {
        transition: all 0.3s ease-in;
        border-bottom: 2px solid transparent;
        &._active {
            border-color: $color-red;
        }
        &:hover {
            border-color: $color-gray;
        }
    }
    // .en-header__link
    &__link {
        font-family: $font-family_Montserrat;
        font-size: 16px;
        line-height: calc(20 / 16 * 100%);
        letter-spacing: 0.72px;
        padding-right: 5px;
        padding-left: 5px;
        white-space: nowrap;
    }
    // .en-header__languages
    &__languages {
        display: flex;
        align-items: baseline;
    }
    // .en-heaeder__lang
    &__lang {
        font-size: 12px;
        line-height: 125%;
        letter-spacing: 0.6px;
        &:not(&_current) {
            color: $color-gray-semi-dark;
            &:hover {
                text-decoration: underline;
            }
        }
        &_current {
            margin-left: 15px;
            border-bottom: 2px solid #000;
        }
    }
}
