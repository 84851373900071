.main-news {
    // .main-news__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        @media (min-width: 768px) {
            margin-bottom: 30px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 40px;
        }
    }
    // .main-news__row
    &__row {
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }
    // .main-news__column
    &__column {
        @media (min-width: 768px) {
            flex: 0 0 50%;
        }
    }
    // .main-news__body
    &__body {
        padding: 40px 30px;
        margin-bottom: 20px;
        background: #e2e2e2;
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }
    // .main-news__date
    &__date {
        margin-bottom: 10px;
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
    }
    // .main-news__title
    &__title {
        margin-bottom: 15px;
        font-family: $font-family_Montserrat;
        font-size: 20px;
        line-height: 140%;
        font-weight: 500;
        @media (min-width: 768px) {
            margin-bottom: 20px;
            font-size: 22px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 25px;
            font-size: 24px;
        }
    }
    // .main-news__text
    &__text {
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
        color: #606060;
        margin-bottom: 30px;
    }
    // .main-news__btn
    &__btn {
        @include btn();
    }
    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 260px;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
}
