@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.eot');
    src: url('Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-LightItalic.woff2') format('woff2'),
        url('Lato-LightItalic.woff') format('woff'),
        url('Lato-LightItalic.ttf') format('truetype'),
        url('Lato-LightItalic.svg#Lato-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff'),
        url('Lato-Regular.ttf') format('truetype'),
        url('Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.eot');
    src: url('Lato-Italic.eot?#iefix') format('embedded-opentype'),
        url('Lato-Italic.woff2') format('woff2'),
        url('Lato-Italic.woff') format('woff'),
        url('Lato-Italic.ttf') format('truetype'),
        url('Lato-Italic.svg#Lato-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lato-Medium.woff2') format('woff2'),
        url('Lato-Medium.woff') format('woff'),
        url('Lato-Medium.ttf') format('truetype'),
        url('Lato-Medium.svg#Lato-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.eot');
    src: url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lato-Bold.woff2') format('woff2'),
        url('Lato-Bold.woff') format('woff'),
        url('Lato-Bold.ttf') format('truetype'),
        url('Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
