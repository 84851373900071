.share {
    // .share__wrap
    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 50px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 65px;
        }
    }
    // .share__text
    &__text {
        margin-right: 30px;
        margin-bottom: 14px;
        color: #888888;
        svg {
            width: auto;
            height: 25px;
        }
    }
    // .share__social
    &__social {
        margin-bottom: 20px;
    }
    // .share__link
    &__link {
        margin-right: 30px;
        font-size: 26px;
        color: #888888;
        svg {
            vertical-align: middle;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            transition: all 0.3s ease 0s;
            svg {
                color: $color-gray;
            }
        }
    }
}
