.en-footer {
    padding: 30px 0;
    background: #010100;
    color: $color-white;
    // .en-footer__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        font-size: 14px;
        line-height: calc(18 / 14 * 100%);
        font-family: $font-family_Lato;
        @media(max-width:767px) {
            text-align: center;
        }
        @media(min-width:768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    // .en-footer__right
    &__right {
        @media(max-width:767px) {
            margin-bottom: 30px;
        }
    }
    // .en-footer__creator
    &__creator {
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
        }
        span {
            font-weight: 600;
            font-size: 16px;
        }
    }
}
