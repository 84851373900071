.modal__bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color: $color-black, $alpha: 0.9);
    &.opened {
        display: block;
        overflow: auto;
    }
    .modal-title {
        font-size: 48px;
        line-height: calc(57 / 48 * 100%);
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
    }
}

.modal__layout {
    padding: 50px 0;
    width: 100%;
    max-width: 710px;
    margin: 0 auto;
    &.opened {

    }
}

.modal__container {
    position: relative;
    padding: 35px 25px 55px;
    background-color: $color-white;
    overflow: hidden;
}

.modal__content {
    position: relative;
    margin-top: 20px;
    z-index: 2;
}

.modal__closer {
    display: block;
    width: 40px;
    height: 40px;
    background: no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjM3NyAxNi41bDYuMjY2LTYuMjY2YS42Mi42MiAwIDEwLS44NzYtLjg3N0wxNi41IDE1LjYyNGwtNi4yNjYtNi4yNjdhLjYyLjYyIDAgMDAtLjg3Ny44NzdsNi4yNjcgNi4yNjYtNi4yNjcgNi4yNjZhLjYyLjYyIDAgMTAuODc3Ljg3N2w2LjI2Ni02LjI2NyA2LjI2NyA2LjI2N2EuNjE4LjYxOCAwIDAwLjg3NiAwIC42Mi42MiAwIDAwMC0uODc3TDE3LjM3NyAxNi41eiIgZmlsbD0iI0Q3MTgyQSIvPjxnIGZpbHRlcj0idXJsKCNwcmVmaXhfX3ByZWZpeF9fZmlsdGVyMF9kKSI+PHBhdGggZD0iTTE2LjUgMS4yNGExNS4yMSAxNS4yMSAwIDAxMTAuNzkgNC40NyAxNS4yMTEgMTUuMjExIDAgMDE0LjQ3IDEwLjc5IDE1LjIxMSAxNS4yMTEgMCAwMS00LjQ3IDEwLjc5IDE1LjIxMSAxNS4yMTEgMCAwMS0xMC43OSA0LjQ3IDE1LjIxMyAxNS4yMTMgMCAwMS0xMC43OS00LjQ3QTE1LjIxMiAxNS4yMTIgMCAwMTEuMjQgMTYuNSAxNS4yMTIgMTUuMjEyIDAgMDE1LjcxIDUuNzEgMTUuMjEyIDE1LjIxMiAwIDAxMTYuNSAxLjI0em0wLTEuMjRDNy4zODcgMCAwIDcuMzg3IDAgMTYuNVM3LjM4NyAzMyAxNi41IDMzIDMzIDI1LjYxMyAzMyAxNi41IDI1LjYxMyAwIDE2LjUgMHoiIGZpbGw9IiNENzE4MkEiLz48L2c+PGRlZnM+PGZpbHRlciBpZD0icHJlZml4X19wcmVmaXhfX2ZpbHRlcjBfZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjQxIiBoZWlnaHQ9IjQxIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+PGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz48ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMTI3IDAiIHJlc3VsdD0iaGFyZEFscGhhIi8+PGZlT2Zmc2V0IGR4PSI0IiBkeT0iNCIvPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMC44NDMxMzcgMCAwIDAgMCAwLjA5NDExNzYgMCAwIDAgMCAwLjE2NDcwNiAwIDAgMCAwLjQgMCIvPjxmZUJsZW5kIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48L3N2Zz4=);
    background-size: contain;
}
