.center {
    // .center__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 30px;
    }
    // .center__row
    &__row {
    }
    // .center__column
    &__column {
    }
    // .center__body
    &__body {
    }
    // .center__logo
    &__logo {
        text-align: center;
        border-bottom: 3px solid $color-gray;
        padding-bottom: 25px;
        margin-bottom: 25px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
        img {
            max-height: 100%;
            object-fit: cover;
        }
    }
    // .center__content
    &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 70px;
    }
    // .center__title
    &__title {
        flex: 0 0 60px;
        margin-bottom: 20px;
        font-family: $font-family_Montserrat;
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        font-weight: 600;
        color: $color-gray;
        @media (min-width: 1140px) {
            font-size: 18px;
            line-height: 22 / 18 * 100%;
        }
        &:hover {
            transition: all 0.3s ease 0s;
            a {
                text-decoration: underline;
            }
        }
    }
    // .center__info
    &__info {
        margin-bottom: 20px;
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        color: #606060;
        span {
            color: $color-black;
        }
    }
    // .center__link-box
    &__link-box {
        display: flex;
        flex-direction: column;
    }
    // .center__link
    &__link {
        color: $color-gray;
        transition: all 0.3s ease-in;
        &:hover {
            text-decoration: underline;
        }
    }
}
