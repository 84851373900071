.company {
    // .company__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 20px;
    }
    // .company__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        font-weight: 500;
    }
    // .company__row
    &__row {
    }
    // .company__column
    &__column {
    }
    // .company__p
    &__p {
        @extend .text;
        max-width: 170px;
        word-wrap: break-word;
        &._hightlight {
            color: $color-red;
        }
    }
}
.swiper-container.swiper-container-black-list {
    padding-bottom: 60px;
}
