.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 0 20px;
    &__number {
        display: inline-flex;
        justify-content: center;
        padding: 0 10px;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 15 / 14 * 100%;
        color: #444444;

        transition: all 0.3s ease 0s;
        &:hover,
        &:active {
            cursor: pointer;
            color: $color-gray;
        }
    }
    // .pagination__first
    &__first {
    }
    // .pagination__prev
    &__prev {
        margin-right: 10px;
    }
    // .pagination__current
    &__current {
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        font-weight: 600;
    }
    // .pagination__next
    &__next {
        margin-left: 10px;
    }
    // .pagination__last
    &__last {
    }
    // .pagination__arrow
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #444444;
        overflow: hidden;
        transition: all 0.3s ease 0s;
        cursor: pointer;
        &:before {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgN2w2LjY1NyA2TDE0IDdNNy41IDB2MTMiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==');
            // font-size: 16px;
            transform: rotate(-90deg);
        }
        &:hover {
            width: 40px;
            height: 40px;
            background-color: $color-gray;
            &:before {
                transform: rotate(-90deg) scale(1.2);
            }
        }
    }
    // .pagination__arrow-prev
    &__arrow-prev {
        transform: rotate(180deg);
    }
    // .pagination__number-box
    &__number-box {
        user-select: none;
        display: flex;
        align-items: center;
        height: 50px;
    }
    // .pagination__arrow-next
    &__arrow-next {
    }
}
