.card-catalog {
    // .card-catalog__item
    &__item {
        display: flex;
        flex-direction: column;
        width: 225px;
        background-color: #f6f6f6;
    }
    // .card-catalog__img
    &__img {
        height: 300px;
        overflow: hidden;
        text-align: center;
        img {
            object-position: top;
        }
    }
    // .card-catalog__content
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 15px 15px;
        text-align: center;
    }
    // .card-catalog__title
    &__title {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 700;
    }
    // .card-catalog__text
    &__text {
        @extend .text;
        margin-bottom: 20px;
        color: $color-gray;
    }
    // .card-catalog__link
    &__link {
        @extend .btn;
        min-width: unset;
        width: 100%;
    }
}
