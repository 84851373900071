.news {
    // .news__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            padding: 0 40px;
        }
    }
    // .news__section-title
    &__section-title {
        @extend .index-title;
        margin-bottom: 20px;
    }
    // .news__text
    &__text {
        @extend .text;
        text-align: center;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            margin-bottom: 50px;
        }
    }
    // .news__column
    &__column {
        display: flex;
        flex-direction: column;
        height: 100%;
        .card-item__side {
            margin-bottom: 0;
        }
        @media (min-width: 768px) {
            // padding: 0 15px;
            flex: 0 0 calc(100% / 2);
        }
        @media (min-width: 1140px) {
            // padding: 0 30px;
            flex: 0 0 calc(100% / 3);
        }
    }
    &__btn-box {
        text-align: center;
    }
    // .news__btn
    &__btn {
        @include btn($color-white, $color-blue-dark);
    }
    &__pagination {
        display: none;
        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 115px;
            margin-bottom: 55px;
        }
    }
    .swiper-container-index-news {
        margin-bottom: 50px;
        .swiper-slide {
            height: auto;
        }
    }
}
