.en-slider {
    padding-top: 100px;
    padding-bottom: 70px;
    background-color: #F2F2F2;
    // .en-slider__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .en-slider__h2
    &__h2 {
        @extend .en-about__h2;
    }
    // .en-slider__slider
    &__slider {
        position: relative;
        .swiper-container {
            max-width: 1000px;
            @media(max-width:374px) {
                max-width: 240px;
            }
            @media(min-width:375px) {
                width: calc(100% - 100px);
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            color: $color-gray;
            &.swiper-button-disabled {
                color: $color-black;
            }
        }
        .swiper-button-prev {
            @media(max-width:374px) {
                left: -7px;
            }
        }
        .swiper-button-next {
            @media(max-width:374px) {
                right: -7px;
            }
        }
    }
}
.slide-item {
    // .slide-item__image
    &__image {
        margin-bottom: 20px;
        text-align: center;
        img {

        }
    }
    // .slide-item__text
    &__text {
        position: relative;
        padding-left: 20px;
        padding-right: 10px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 50px;
            background-color: $color-blue-en;
        }
    }
}
