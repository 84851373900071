.b-company {
    padding-top: 35px;
    // .b-company__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 50px;
    }
    // .b-company__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        font-weight: 500;
    }
    // .b-company__row
    &__row {
        @media(max-width:767px) {
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            display: flex;
        }
    }
    // .b-company__img
    &__img {
        text-align: center;
        padding: 20px 0px;
        background-color: #000;

        @media(max-width:767px) {
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            flex: 0 0 216px;
            img {
                min-height: 100px;
            }
        }
        @media(min-width:1920px) {
            flex: 0 0 270px;
        }
    }
    // .b-company__content
    &__content {
        text-align: center;
        @extend .admin;
    }
    // .b-company__text
    &__text {
        @extend .admin;
    }
}
