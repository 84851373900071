.action {
    // .action__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 80px;
        @media (min-width: 768px) {
            padding-top: 40px;
        }
    }
    // .action__column
    &__column {
        display: block;
        width: 100%;
        height: 100%;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 7px + 10px;
            width: calc(100% - 34px);
            height: 6px;
            background-color: transparent;
            transition: all 0.3s ease-in;
        }
        &:hover {
            &:after {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 7px + 10px;
                width: calc(100% - 34px);
                height: 6px;
                background-color: $color-blue-dark;
            }
        }
    }
    // .action__body
    &__body {
        display: flex;
        position: relative;
        min-height: 260px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #f2f2f2;
        // .action__body_first
        &_first {
            background: $color-blue-dark;
            .action__item-text,
            .action__item-title {
                color: $color-white;
            }
        }
    }
    // .action__item
    &__item {
        display: flex;
        flex-direction: column;
        padding: 40px 25px;
        // margin-right: 7px;
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .action__item-title
    &__item-title {
        margin-bottom: 15px;
        font-family: $font-family_Montserrat;
        font-size: 24px;
        line-height: calc(29 / 24 * 100%);
        font-weight: 700;
    }
    // .action__item-text
    &__item-text {
        @extend .text;
        flex: 1 1 auto;
        margin-bottom: 20px;
    }
    // .action__btn
    &__btn {
        @include btn($color-white, $color-blue-dark);
        position: relative;
        margin-bottom: 10px;
        z-index: 2;
        &_white {
            border: 1px solid $color-white;
            color: $color-white;
            &:hover {
                background-color: $color-white;
                border: 1px solid transparent;
                color: $color-blue-dark;
            }
        }
    }
    // .action__img
    &__img {
        margin-bottom: 20px;
        text-align: center;
    }
    //.action__pagination
    &__pagination {
        @extend .news__pagination;
    }
    .swiper-container-action {
        padding-bottom: 6px;
        margin-bottom: 45px;
        // .swiper-slide {

        //     &_large {
        //         .action__img {
        //             img {
        //                 top: 50%;
        //                 transform: translateY(-50%);
        //             }
        //         }
        //     }
        //     // .swiper-slide_small
        //     &_small {
        //     }
        // }
        @media(max-width:767px) {
            .swiper-slide {
                height: unset;
                // .swiper-slide_large
                .action__item {
                    // flex: 1 0 calc(230px + (410 - 230) * ((100vw - 320px) / (767 - 320)));
                }
                // &_large {
                //     .action__img {
                //         flex: 0 0 calc(60px + (325 - 60) * ((100vw - 320px) / (767 - 320)));
                //     }
                // }
                // // .swiper-slide_small
                // &_small {
                //     .action__img {
                //         flex: 0 0 calc(60px + (170 - 60) * ((100vw - 320px) / (767 - 320)));
                //     }
                // }
            }
        }
        @media(min-width:768px) {
            .swiper-wrapper {
                flex-wrap: wrap;
                overflow: hidden;
            }
            .swiper-slide {
                position: relative;
                height: auto;
                margin-bottom: 20px;
                padding: 0 10px;
                .action__item {
                    flex-direction: row;
                    flex-wrap: wrap;

                    .action__img {
                        position: absolute;
                        margin-bottom: 0;
                    }
                    .action__btn {
                        align-self: flex-end;
                    }
                }
                // .swiper-slide_large
                &_large {
                    flex: 1 1 auto;
                    .action__item {
                        flex: 1 0 calc(410px + (325 - 410) * ((100vw - 320px) / (1920 - 320)));
                        max-width: 60%;
                    }
                    .action__img {
                        top: 50%;
                        left: 50%;
                        transform: translate(30%, -50%);
                    }
                }
                // .swiper-slide_small
                &_small {
                    flex: 1 1 calc(100% / 2);
                    .action__item {
                        flex: 1 0 245px;
                        max-width: 66%;
                        padding-right: 0;
                    }
                    .action__img {
                        bottom: 0%;
                        left: 50%;
                        transform: translateX(66%);
                    }
                }
            }
        }
        @media(min-width:1440px) {
            .swiper-slide {
                // .swiper-slide_large
                &_large {
                    flex: 1 1 calc((100% / 3) * 2);
                }
                // .swiper-slide_small
                &_small {
                    flex: 1 1 calc(100% / 3);
                    .action__item {
                        max-width: 75%;
                    }
                }
            }
        }
        @media(min-width:1920px) {
            .swiper-slide {
                // .swiper-slide_large
                &_large {
                    .action__item {
                        flex: 1 0 325px;
                    }
                }
                // .swiper-slide_small
                &_small {
                    .action__item {
                        flex: 1 0 245px;
                    }
                }
            }
        }
    }
}
