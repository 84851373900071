.about-content {
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    &:not(._dark) {
        background: $color-white;
    }
    &._dark {
        background: $color-dark;
        color:$color-white;
    }
    &._reverse {
        @media (min-width: 768px) {
            .about-content__wrap {
                flex-direction: row-reverse;
            }
            .about-content__body {
                padding-right: 0;
                padding-left: 20px;
            }
            .about-content__image {
                transform: translateX(-200%);
            }
        }
    }
    &._dark._reverse {
        .about-content__image {
            @include image-shadow($color-dark, 'left');
        }
    }
    &._dark:not(._reverse) {
        .about-content__image {
            @include image-shadow($color-dark);
        }
    }
    &:not(._dark)._reverse {
        .about-content__image {
            @include image-shadow($color-white, 'left');
        }
    }
    &:not(._dark):not(._reverse) {
        .about-content__image {
            @include image-shadow($color-white);
        }
    }
    @media (min-width: 768px) {
        flex-direction: row;
    }
    // .about-content__content
    &__content {
        flex: 0 0 100%;
        display: flex;
    }
    // .about-content__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: 100%;
    }
    // .about-content__body
    &__body {
        width: 100%;
        margin-top: 45px;
        margin-bottom: 45px;
        @media (min-width: 768px) {
            width: 50%;
            margin-top: 60px;
            margin-bottom: 80px;
            padding-right: 20px;
        }
        @media (min-width: 1140px) {
            margin-top: 95px;
            margin-bottom: 135px;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .about-content__title
    &__title {
        @extend .h2;
        margin-bottom: 15px;
    }
    // .about-content__text
    &__text {
        @extend .text;
        margin-bottom: 30px;
        @media (min-width: 1140px) {
            margin-bottom: 40px;
        }
    }
    // .about-content__list-name
    &__list-name {
        margin-bottom: 15px;
        font-family: $font-family_Lato;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
    }
    // .about-content__list
    &__list {
        margin-bottom: 30px;
    }
    // .about-content__item
    &__item {
        @extend .item;
    }
    // .about-content__btn
    &__btn {
        @extend .btn;
    }
    // .about-content__image
    &__image {
        min-height: 220px;
        position: relative;
        padding: 0px 0px 30% 0px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (min-width: 768px) {
            flex: 0 0 50%;
            transform: translateX(-100%);
        }
    }
}
