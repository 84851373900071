.catalog {
    // .catalog__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .catalog__row
    &__row {
        display: flex;
        flex-wrap: wrap;
    }
    // .catalog__card
    &__card {
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        @media(min-width:600px) {
           flex: 1 1 calc(100% / 2);
        }
        @media(min-width:800px) {
            flex: 1 1 calc(100% / 3);
        }
        @media(min-width:1000px) {
            flex: 1 1 calc(100% / 4);
        }
    }
    // .catalog__filter
    &__filter {
        margin-bottom: 30px;
    }
}
