.person {
    // .person__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        @media (min-width: 768px) {
            margin-bottom: 55px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 75px;
        }
    }
    // .person__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        font-weight: 500;
    }
    // .person__body
    &__body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 25px;
        @media (min-width: 768px) {
            flex-direction: row;
            margin: 0 -10px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 10px;
        }
    }
    // .person__item
    &__item {
        flex: 1 1 100%;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
            padding: 0 10px;
            flex: 1 1 50%;
        }
        @media (min-width: 1140px) {
            flex: 0 1 33.333%;
        }
    }
    // .person__name
    &__name {
        @extend .text;
    }
    // .person__description
    &__description {
        @extend .text;
    }
    // .person__warning
    &__warning {
        background: #e1e1e1;
        margin: 0 -15px;
        @media (min-width: 768px) {
            margin: 0;
        }
    }
    // .person__warning-body
    &__warning-body {
        padding: 25px 15px;
        @media (min-width: 768px) {
            padding: 25px 40px;
        }
    }
    // .person__warning-text
    &__warning-text {
        font-family: $font-family_Montserrat;
        font-size: 18px;
        line-height: 28 / 18 * 100%;
        color: $color-black;
    }
    // .person__warning-link
    &__warning-link {
        color: $color-gray;
        border-bottom: 2px dashed $color-gray;
    }
}
