.current-info {
    // .current-info__wrap
    &__wrap {
    }
    // .current-info__body
    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        @media (min-width: 1140px) {
            margin-bottom: 50px;
        }
    }
    // .current-info__date
    &__date {
        padding-bottom: 20px;
        margin-right: 20px;
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
        white-space: nowrap;
    }
    // .current-info__category
    &__category {
        @extend .head__link;
        color: $color-black;
    }
    &__line {
        color: #e1e1e1;
        margin: 0 20px;
    }
    // .current-info__name
    &__name {
        @extend .head__link;
        color: $color-black;
    }
}
