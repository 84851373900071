.b-tech {
    background-color: $color-gray-light;
    padding: 50px 0;
    // .b-tech__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .b-tech__h2
    &__h2 {
        @extend .h2;
        text-align: center;
        margin-bottom: 25px;
    }
}
.b-tech-swiper {
    // .b-tech-swiper__img
    &__img {
        position: relative;
        max-width: 345px;
        height: 190px;
        margin: 0 auto 20px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // .b-tech-swiper__name
    &__name {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 140%;
        font-weight: 500;
    }
    // .b-tech-swiper__desc
    &__desc {
        @extend .text;
        margin-bottom: 15px;
    }
    // b-tech-swiper__pagination
    &__pagination {
        text-align: center;
    }
}
.swiper-container {
}
.swiper-button-prev {
}
.swiper-wrapper {
}
.swiper-slide {
}
.swiper-button-next {
}
.swiper-pagination {
}
.swiper-scrollbar {
}
