.head {
    &__black {
        background: #1c1d1f;
        .head__current,
        .head__slash,
        .head__title {
            color: $color-white;
        }
    }
    // .head__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-bottom: 25px;
        padding-top: 25px;
        @media (min-width: 768px) {
            padding-bottom: 40px;
        }
    }
    // .head__crumbs
    &__crumbs {
        margin-bottom: 45px;
        @media (min-width: 768px) {
            margin-bottom: 60px;
        }
    }
    // .head__link
    &__link {
        display: inline-block;
        margin-bottom: 20px;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        color: #c1c1c1;
        border-bottom: 2px dashed $color-gray;
    }
    // .head__slash
    &__slash {
        margin: 0 15px;
    }
    // .head__current
    &__current {
        display: inline-block;
        margin-bottom: 20px;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        font-weight: 700;
    }
    // .head__title
    &__title {
        font-family: $font-family_Montserrat;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
    }
}
