.map {
    position: relative;
    z-index: 1;
    overflow: hidden;
    // .map__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 50px;
        @media (min-width: 768px) {
            margin-bottom: 55px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 65px;
        }
        #service-map,
        #map-contact {
            width: 100%;
            min-height: 340px;
        }
    }

    #map {
        width: 100%;
        height: 580px;
    }
    // .map__content
    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 20px;
        margin-bottom: 45px;
        margin-top: 50px;
    }
    // .map__slider
    &__slider {
        position: relative;
    }
    // .map__h2
    &__h2 {
        @extend .h2;
        text-align: center;
        margin-bottom: 40px;
    }
    // .map__dropdown
    &__dropdown {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin-bottom: 20px;
        &:after {
            content: url("/static/images/image-icons/arrow2.svg");
            position: absolute;
            right: 15px;
            width: 15px;
            top: 54px;
            height: auto;
            pointer-events: none;
        }
    }
    // .map__label
    &__label {
        @extend .text;
        margin: 0px 0px 15px 0px;
    }
    // .map__select
    &__select {
        display: inline-flex;
        align-items: center;
        height: 48px;
        max-width: 100%;
        padding: 0px 40px 0px 20px;
        border: 1px solid $color-gray;
        font-size: 20px;
        line-height: calc(30 / 22 * 100%);
        appearance: none;
    }

    // .map__pagination
    &__pagination {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-bottom: 55px;
        pointer-events: none;
        @media(max-width:48em) {
            max-width: 115px;
        }
        @media (min-width: 48em) {
            position: absolute;
            top: 12.5%;
            left: 50%;
            width: 100%;
            transform: translate(-50%);
            z-index: 3;
            pointer-events: none;
            .pagination__arrow-prev,
            .pagination__arrow-next {
                position: absolute;
            }
            .pagination__arrow-prev {
                left: -35px;
            }
            .pagination__arrow-next {
                right: -35px;
            }
        }
        @media (min-width: 80em) {

            .pagination__arrow-prev {
                left: -55px;
            }
            .pagination__arrow-next {
                right: -55px;
            }
        }
        .pagination__arrow-prev,
        .pagination__arrow-next {
            pointer-events: all;
        }
    }
}
