.description {
    // .description__wrap
    &__wrap {
        @include x-container(715px);
        margin: 0 auto;
    }
    // .description__row
    &__row {
        display: flex;
        flex-direction: column;
    }
    // .description__column
    &__column {
    }
    // .description__img
    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
    // .description__body
    &__body {
        margin-bottom: 30px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }

        ul {
            margin: 20px 0;
            li {
                margin-left: 30px;
                list-style: disc;
            }
        }

        p {
            margin-bottom: 40px;
            font-family: $font-family_Lato;
            font-size: 14px;
            line-height: 22 / 14 * 100%;
            @media (min-width: 768px) {
                margin-bottom: 20px;
            }
            @media (min-width: 1140px) {
                font-size: 16px;
                line-height: 140%;
            }
        }
        a {
            color: $color-gray;
            border-bottom: 2px dashed $color-gray;

            &:hover {
                border-bottom: 2px solid $color-gray;
            }
        }

        img{
            max-width: 100%;
            height: auto;
            margin: 0px auto;
        }
    }
}
