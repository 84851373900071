.become-form {
    // .become-form__wrap
    &__wrap {
        margin-top: 20px;
        margin-bottom: 70px;
        @media (min-width: 768px) {
            margin-top: 50px;
        }
        @media (min-width: 1140px) {
            margin-top: 60px;
        }
    }
    // .become-form__container
    &__container {
        margin: 0 auto;
        max-width: 450px;
        @media (min-width: 1140px) {
            max-width: 850px + 20px;
        }
    }
    // .become-form__h2
    &__h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    // .become-form__form
    &__form {
        display: flex;
        flex-direction: column;
        @media (min-width: 1140px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
        input {
            @media (min-width: 1140px) {
                &:not(:last-child) {
                    flex: 0 0 calc(33.333% - 20px);
                    margin: 0 10px 28px;
                }
            }
            border: 1px solid #606060;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    // .become-form__label
    &__label {
        margin-bottom: 20px;
        max-width: 710px;
        margin-right: auto;
        margin-left: auto;
    }
    // .become-form__checkbox
    &__checkbox {
    }
    // .become-form__btn
    &__btn {
        @include btn();
        align-self: center;
        min-width: 215px;
    }
}
