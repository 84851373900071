@import "./filter-common";
.filter {
    &__sity-box {
        max-height: 350px;
    }
    &__sity-name {
        display: block;
        font-family: $font-family_Montserrat;
        font-size: 20px;
        line-height: 200%;
        padding: 0 20px;
        cursor: pointer;
        color: $color-gray;

        text-decoration: transparent;
        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }

    &__sity {
        appearance: none;
    }
}
