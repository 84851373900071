.footer {
    background-color: #010100;
    // .footer__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 35px;
        padding-bottom: 30px;
        @media (min-width: 768px) {
            padding-bottom: 50px;
        }
        @media (min-width: 1140px) {
            padding-bottom: 50px;
        }
    }
    // .footer__logo
    &__logo {
        img {
            width: 140px;
            height: 60px;
        }
        margin-bottom: 20px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
            img {
                width: 95px;
            }
        }
        @media (min-width: 1140px) {
            margin-bottom: 10px;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }
    // .footer__row
    &__row {
        display: flex;
        @media (max-width: 1139px) {
            flex-wrap: wrap;
        }
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    // .footer__column
    &__column {
        &_1 {
            flex: 0 1 auto;
            text-align: center;
            margin-bottom: 50px;
        }
        &_2 {
            flex: 0 1 auto;
            margin-bottom: 30px;
        }
        &_3 {
            flex: 1 1 100%;
        }
        @media (min-width: 768px) {
            &_1 {
                text-align: left;
                flex: 0 1 220px;
                margin-right: 50px;
            }
            &_2 {
                flex: 1 1 calc(100% - 220px - 50px);
            }
        }
        @media (min-width: 1140px) {
            &_1 {
                text-align: left;
                flex: 0 1 220px;
                margin-top: 15px;
                margin-right: 100px;
            }
            &_2 {
                flex: 1 1 calc(100% - 220px - 50px);
                margin-top: 15px;
            }
            &_3 {
                flex: 0 0 325px;
            }
        }
    }
    // .footer__number
    &__number {
        display: inline-block;
        margin-bottom: 15px;
        font-family: $font-family_Montserrat;
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        font-weight: 600;
        letter-spacing: 0.8px;
        color: #dfdfdf;
        white-space: nowrap;

        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
    // .footer__adress
    &__adress {
        font-family: $font-family_Montserrat;
        font-size: 12px;
        line-height: 18 / 12 * 100%;
        color: $color-white;
    }
    // .footer__list
    &__list {
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease 0s;
        @media (max-width: 767px) {
            display: none;
            &_active {
                display: flex;
            }
        }
    }
    // .footer__menu-btn
    &__menu-btn {
        @extend .footer__btn;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        margin-bottom: 25px;
        border-top: 1px solid #606060;
        border-bottom: 1px solid #606060;
        &_active {
            svg {
                transform: rotate(180deg);

            }
        }
        @media (min-width: 768px) {
            display: none;
        }
    }
    // .footer__item
    &__item {
        display: inline-block;
        flex: 0 0 100%;
        padding: 20px;
        @media (min-width: 768px) {
            flex: 0 0 33.333%;
            padding: 0 20px 20px;
        }
        @media (min-width: 1140px) {
            flex: 0 0 50%;
        }
    }
    // .footer__link
    &__link {
        font-family: $font-family_Montserrat;
        font-size: 12px;
        line-height: 15 / 12 * 100%;
        letter-spacing: 0.6px;
        color: $color-white;

        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
    // .footer__search
    &__search {
        margin-bottom: 15px;
        @media (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
    // .footer__search-label
    &__search-label {
        @extend .menu__search-label;
        color: #aaa;

        .footer__search-line,
        button {
            transition: all 0.3s ease 0s;
        }
        button {
            &:hover {
                color: red;
            }
        }
        &:hover {
            .footer__search-line, button {
                border-color: $color-gray;
            }
        }
    }
    // .footer__search-line
    &__search-line {
        @extend .menu__search-line;
        color: #aaa;
    }
    // .footer__content
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        white-space: nowrap;
        @media (max-width: 1139px) {
            justify-content: center;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
    }
    // .footer__cabiner
    &__cabiner {
        @extend .footer__btn;
        display: inline-flex;
        flex-direction: row-reverse;
        transition: all 0.3s ease-in;

        &:hover {
            color: $color-gray;
        }

        @media (max-width: 1139px) {
            margin-right: 30px;
        }
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
    // .footer__dealer
    &__dealer {
        @extend .footer__btn;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 35px;
        height: 42px;
        border: 1px solid #888888;
        border-radius: 21px;

        transition: all 0.3s ease 0s;
        &:hover {
            color: $color-gray;
            border-color: $color-gray;
        }

        @media (min-width: 1140px) {
            height: 32px;
            padding: 0 15px;
        }
    }
    // .footer__social
    &__social {
        display: flex;
        align-items: center;
        font-family: $font-family_Montserrat;
        font-size: 12px;
        line-height: 15 / 12 * 100%;
        letter-spacing: 0.6px;
        color: #aaa;
        p {
            flex: 0 1 auto;
            margin-right: 20px;
        }
        @media (max-width: 1139px) {
            flex-direction: column;
            p {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }
    }
    // .footer__social-link-box {
    &__social-link-box {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            margin-bottom: 30px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 0;
        }
    }
    // .footer__social-link
    &__social-link {
        margin-right: 20px;
        font-size: 24px;
        color: $color-white;
        transition: all 0.3s ease 0s;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 1139px) {
            margin-right: 30px;
        }
        @media (min-width: 1140px) {
            font-size: 18px;
        }

        &:hover {
            svg {
                color: $color-gray;
            }
        }
    }
    &__btn {
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        letter-spacing: 0.7px;
        color: #aaa;
        @media (min-width: 1140px) {
            font-size: 12px;
            line-height: 15 / 12 * 100%;
            letter-spacing: 0.6px;
        }
    }
    // .footer__description
    &__description {
        @extend ._text;
        text-align: center;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #606060;
        @media (min-width: 768px) {
            text-align: left;
        }
    }
    // .footer__license
    &__license {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media (min-width: 1140px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    // .footer__rules
    &__rules {
        @extend ._text;
        margin-bottom: 20px;
        @media (min-width: 1140px) {
            margin-bottom: 0;
        }
    }
    // .footer__created-moow
    &__created-moow {
        @extend ._text;
        line-height: 14 / 12 * 100%;
        a {
            transition: all 0.3s ease 0s;
            &:hover {
                text-decoration: underline;
                color: $color-gray;
            }
        }
    }
    ._text {
        font-family: $font-family_Lato;
        font-size: 12px;
        line-height: 18 / 12 * 100%;
        color: #606060;
    }
}
