.email {
    background-color: #f2f2f2;
    // .email__wrap
    &__wrap {
        padding-top: 30px;
        padding-bottom: 50px;
        @media (min-width: 768px) {
            padding-top: 40px;
            padding-bottom: 60px;
        }
        @media (min-width: 1140px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
    // .email__container
    &__container {
        position: relative;
        @include x-container(955px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    // .email__item
    &__item {
        flex: 0 1 auto;
    }
    // .email__title
    &__title {
        font-family: $font-family_Montserrat;
        font-size: 24px;
        line-height: 40 / 30 * 100%;
        @media (min-width: 768px) {
            margin-right: 20px;
        }
        @media(min-width:1440px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
    // .email__form
    &__form {
    }
}
.form-email {
    display: flex;
    width: 100%;
    // .form-email__success
    &__success {
        display: none;
    }
    &.success + .form-email__success {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 2rem;
        z-index: 2;
        background-color: #f2f2f2;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
    // .form-email__field
    &__field {
        flex: 1 1 100%;
        @include form-field($color-gray);
        .label {
            &::before, &::after {
                content: none;
            }
        }
        @media (min-width: 768px) {
            margin-right: 20px;
        }
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }
    // .form-email__btn
    &__btn {
        @extend .btn-shadow;
        height: 100%;
        @media (min-width: 768px) {
            flex: 0 1 auto;
            min-width: 170px;
        }
        @media (max-width: 767px) {
            margin-left: 0;
        }
    }
}
