.info {
    background-color: #1f292d;
    // .info__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 80px;
    }
    // .info__cube
    &__cube {
        position: relative;
        width: 26px;
        height: 26px;
        margin-left: 25px;
        margin-bottom: 40px;
        background: $color-gray;
        box-shadow: 5px 5px 0 0 $color-blue;
        .svg-icon {
            position: absolute;
        }
        .icon-dealer {
            top: -70%;
            left: -90%;
            font-size: 35px;
            font-weight: 500;
        }
        .icon-location {
            top: -80%;
            left: -60%;
            font-size: 44px;
        }
        .icon-ggg {
            top: -80%;
            left: -130%;
            font-size: 42px;
        }
        .icon-window {
            top: -70%;
            left: -90%;
            font-size: 40px;
        }
    }
    // .info__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        @media (min-width: 768px) {
            margin: 0 -35px;
        }
    }
    // .info__column
    &__column {
        position: relative;
        margin-bottom: 50px;
        flex: 1 1 100%;
        padding: 0 15px;
        @media (min-width: 768px) {
            padding: 0 35px;
            flex: 0 0 50%;
        }
        @media (min-width: 1140px) {
            flex: 0 0 25%;
        }
        display: flex;
        flex-direction: column;
    }
    // .info__img
    &__img {
    }
    // .info__title
    &__title {
        margin-bottom: 15px;
        font-family: $font-family_Montserrat;
        font-size: 18px;
        line-height: 22 / 18 * 100%;
        font-weight: 500;
        color: $color-white;
        a {
            @extend .decor-link;
        }
    }
    // .info__text
    &__text {
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 22 / 14 * 100%;
        color: $color-gray-light;
        @media (min-width: 1140px) {
            font-size: 16px;
            line-height: 22 / 16 * 100%;
        }
    }
    &__text a,  &__select {
        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
    // .info__select
    &__select {
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuMzE0IDYuNjU3TDYuNjU3IDEgMSA2LjY1NyIgc3Ryb2tlPSIjZTFlMWUxIi8+PC9zdmc+');
            display: inline-block;
            vertical-align: middle;
            transform: rotate(180deg);
            margin-left: 5px;
            height: 25px;
        }
        &_active {
            &:after {
                transform: rotate(0deg);
            }
        }
    }
    // .info__body
    &__body {
        display: none;
        &_active {
            position: absolute;
            top: 110%;
            left: 0;
            width: 100%;
            display: block;
            padding: 0 15px;
            z-index: 20;
            @media (min-width: 768px) {
                padding: 0 35px;
            }
            @media (min-width: 1140px) {
                top: 90%;
            }
        }
    }
    // .info__body-content
    &__body-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 200px;
        max-width: 600px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.9);
        box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
        overflow-y: auto;
        a {
            display: inline-block;
            padding: 0 20px;
            font-family: $font-family_Montserrat;
            font-size: 14px;
            line-height: 200%;
            color: $color-white;
            cursor: pointer;
            text-decoration: transparent;
            transition: all 0.3s ease 0s;
            &:hover {
                text-decoration: underline;
            }
        }
        input {
            appearance: none;
        }
    }
}
