.event {
    background: #f2f2f2;
    // .event__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 60px;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            padding-bottom: 55px;
            padding-left: 40px;
            padding-right: 40px;
        }
        @media (min-width: 1440px) {
            padding-bottom: 80px;
        }
    }
    // .event__h2
    &__h2 {
        @extend .index-title;
        margin-bottom: 20px;
    }
    // .event__text
    &__text {
        @extend .text;
        margin-bottom: 35px;
        text-align: center;
        @media (min-width: 1140px) {
            text-align: left;
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
    }
    // .event__item
    &__item {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: 45px;
        }
        @media(min-width:1140px) {
            align-items: center;
            max-width: 1050px;
            flex-direction: row;
        }
    }
    // .event__column
    &__column {
        flex: 0 1 50%;
        display: flex;
        justify-content: center;
        @media (min-width: 1140px) {
            padding: 0 50px;
        }
        @media (min-width: 1440px) {
            padding: 0;
            justify-content: flex-start;
        }
    }
    // .event__img
    &__img {
        flex: 1 1 auto;
        position: relative;
        min-height: 300px;
        box-shadow: 5px 5px 0 0 $color-gray;
        margin-bottom: 50px;
        transition: box-shadow 0.3s ease-in;
        &:hover {
            box-shadow: -5px -5px 0 0 $color-gray;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        @media (min-width: 768px) {
            flex: 0 0 690px;
        }
        @media (min-width: 1140px) {
            flex: 0 0 460px;
            margin-bottom: 0;
        }
    }
    // .event__content
    &__content {
        flex: 1 1 auto;
        text-align: center;
        @media (min-width: 1140px) {
            text-align: left;
        }
    }
    // .event__title
    &__title {
        margin-bottom: 25px;
        font-family: $font-family_Montserrat;
        font-size: 18px;
        line-height: 22 / 18 * 100%;
        font-weight: 700;
        color: $color-gray;
        @media (min-width: 768px) {
            margin-bottom: 35px;
            font-size: 28px;
            line-height: 34 / 28 * 100%;
        }
        a {
            &:hover {
                transition: all 0.3s ease 0s;
                text-decoration: underline;
            }
        }
    }
    // .event__description
    &__description {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 35px;
        }
        font-family: $font-family_Lato;
        font-size: 16px;
        line-height: 22 / 16 * 100%;
        color: #606060;
        span {
            color: $color-black;
        }
    }
    // .event__btn
    &__btn {
        @include btn();
    }
}
