.en-about {
    padding: 50px 0;
    // .en-about__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .en-about__h2
    &__h2 {
        @extend .h2;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 5px;
        text-align: center;
        font-weight: 700;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 370px;
            height: 2px;
            background-color: $color-blue-en;
        }
        @media(min-width:768px) {
            margin-bottom: 70px;
        }
    }
    // .en-about__content
    &__content {
        position: relative;
    }
    // .en-about__text
    &__text {
        max-width: 920px;
        margin: 0 auto 30px;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.066px;
        text-align: center;
    }
    // .en-about__social
    &__social {
        display: flex;
        align-items: center;
        @media(max-width:1199px) {
            justify-content: space-around;
            max-width: 320px;
            margin: 0 auto 30px;
        }
        @media(min-width:1200px) {
            position: absolute;
            bottom: 0;
            right: 0;
            flex-direction: column;
        }
    }
    // .en-about__social-link
    &__social-link {
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
        }
        @media(min-width:1200px) {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
    // .en-about__link
    &__link {
        @extend .decor-link;
        display: inline-flex;
        flex-direction: row-reverse;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        font-weight: 600;
        &::before {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjI2MiAxMi45NWMtLjI0LS4xMjYtLjMzMS0uNDQxLS4yMDUtLjY5Ny4wMi0uMDQxIDEuMzEyLTEuMzU0IDIuODcxLTIuOTEzTDUuNzY0IDYuNSAyLjkyOCAzLjY2NkMxLjM3IDIuMTAzLjA3OC43OS4wNTguNzQ5QS41Mi41MiAwIDAxLjI2Ny4wNTFDLjM5My0uMDE1LjYyNy0uMDE3Ljc0LjA0NmMuMTI5LjA2OSA2LjE0NCA2LjA5IDYuMjA0IDYuMjExLjAzOC4wNjkuMDU2LjE1LjA1Ni4yNDRhLjQ5Ni40OTYgMCAwMS0uMDU2LjI0NGMtLjA2My4xMjItNi4wNzggNi4xNDItNi4yMDQgNi4yMWEuNjI4LjYyOCAwIDAxLS40NzgtLjAwNHoiIGZpbGw9IiM1MDUwNTAiLz48L3N2Zz4=');
            margin-right: 7px;
            margin-left: 7px;
        }
    }
}
