.b-download {
    background-color: $color-gray-dark;
    padding: 45px 0;
    @media(min-width:768px) {
        padding: 60px 0;
    }
    // .b-download__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .b-download__h2
    &__h2 {
        @extend .h2;
        @extend .index-title;
        margin-bottom: 60px;
        color: $color-white;
    }
    // .b-download__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        overflow: hidden;
    }
    // .b-download__item
    &__item {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            flex: 0 1 calc(100% / 2);
        }
    }
    // .b-download__image
    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 135px;
        height: 170px;
        margin-right: 20px;
        img {
            object-fit: cover;
        }
    }
    // .b-download__link
    &__link {
        color: $color-white;
        p {
            font-size: 18px;
            line-height: calc(22 / 18 * 100%);
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
        p:last-child {
            font-weight: 700;
        }
    }
}
