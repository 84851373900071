.en-video {
    position: relative;
    padding: 0;
    //. en-video__h2
    &__h2 {
        @extend .brands__title;
    }
    .b-video {
        // .b-video__wrap
        &__wrap {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            height: 100%;
            z-index: 3;
            padding-top: 30px;
            padding-bottom: 30px;
            transform: translateX(-50%);
            pointer-events: none;
            @media(min-width:768px) {
                padding-top: 70px;
                padding-bottom: 70px;
            }
        }
        // .b-video__item
        &__item {
            margin-bottom: 0;
            @media(min-width:768px) {
                padding-bottom: 30.25%;
            }
        }
    }
    .item-video {
        // .item-video__poster
        &__poster {
        }
        // .item-video__poster-btn
        &__poster-btn {
            height: auto;
            svg {
                color: $color-white;
                width: 100%;
                height: 100%;
                &:hover {
                    color: $color-red;
                }
            }
        }
        // .item-video__video
        &__video {
        }
    }
    .video {
        // .video__info
        &__info {
        }
        // .video__name
        &__name {
        }
        // .video__text
        &__text {
        }
    }
}
