.slider-new {
    // .slider-new__wrap
    &__wrap {
        position: relative;
    }
    // .slider-new__image
    &__image {
        a,
        img {
            display: block;
            line-height: 0;
        }
        @media(min-width:1920px) {
            position: relative;
            padding-bottom: 31.25%;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

[data-swiper-index-new] {
    .swiper-slide {
        height: auto;
    }
}

[data-swiper-index-new-pagination].swiper-pagination {
    background-color: $color-gray;
    position: relative;
    z-index: 2;
    padding-top: 20px;
    margin-top: -5px;
    font-size: 0;
    .swiper-pagination-bullet {
        margin: 0 4px;
    }
}
