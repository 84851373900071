.advantages {
    padding-top: 40px;
    background: #f2f2f2;
    // .advantages__wrap
    &__wrap {
        @include x-container;
margin: 0 auto;
    }
    // .advantages__h2
    &__h2 {
        @include index-title($color-blue-light);
        margin-bottom: 60px;
        @media (min-width: 768px) {
            margin-bottom: 65px;
        }
        @media (min-width: 1200px) {
            margin-bottom: 80px;
        }
    }
    // .advantages__item
    &__item {
        margin-bottom: 60px;
    }
    // .advantages__top
    &__top {
        padding-bottom: 25px;
        margin-bottom: 10px;
        font-size: 72px;
        line-height: calc(88 / 72 * 100%);
        font-weight: 500;
        border-bottom: 2px solid $color-blue-light;
        color: $color-blue-light;
        img {
            max-width: 100%;
            height: 70px;
        }
    }
    // .advantages__bottom
    &__bottom {
        @extend .h2;
        color: $color-blue-light;
    }
    .swiper-container-advantages {
        .swiper-wrapper {
            flex-direction: row;
            // &__item
            margin-bottom: calc(100px - 60px);
        }
        .swiper-pagination-advantages {
            position: absolute;
            text-align: center;
        }
    }
}
