.check {
    // .check__wrap
    &__wrap {
        @include x-container(565px);
        margin: 0 auto 60px;
    }
    // .check__h2
    &__h2 {
        @extend .h2;
        text-align: center;
        font-weight: 500;
        margin-bottom: 30px;
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
    }
    // .check__form
    &__form {
        display: flex;
        flex-direction: column;
        @include form-field;
        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }
    // .check__input
    &__input {
        margin: 0px 0px 20px 0px;
        @media (min-width: 768px) {
            flex: 1 1 auto;
            margin: 0px 20px 0px 0px;
        }
    }
    // .check__btn
    &__btn {
        @extend .btn;
    }
}
