.menu {
    position: relative;
    &_active {
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 100px;
        background-color: $color-white;
        z-index: 10;
        .menu__burger {
            right: 15px;
        }
    }

    // .menu__wrap
    &__wrap {
    }
    // .menu__burger
    &__burger {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        width: 30px;
        height: 30px;
        z-index: 15;

        span {
            position: absolute;
            top: 50%;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 30px;
                height: 2px;
                background-color: $color-white;
                transition: all 0.3s ease 0s;
            }

            &::after {
                content: "";
                position: absolute;
                top: -10px;
                width: 30px;
                height: 2px;
                background-color: $color-white;
                transition: all 0.3s ease 0s;
            }
        }

        &_active {
            span {
                &::before {
                    background-color: $color-black;
                    transform: rotate(-45deg);
                    top: 0;
                }

                &::after {
                    background-color: $color-black;
                    transform: rotate(45deg);
                    top: 0;
                }
            }
        }
        @media (max-width: 1139px) {
            display: block;
            z-index: 10;
        }
        @media (min-width: 768px) {
            // right: 40px;
        }
    }
    // .menu__list
    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 150px;

        &_active {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: stretch;
            .menu__logo,
            .menu__description,
            .menu__number {
                display: none;
            }
            .menu__languages {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 0 0 90px;
            }
            .menu__btn {
                display: inline-flex;
            }
            .menu__search,
            .bottom-header__list {
                display: block;
            }
            .menu__dealer {
                color: $color-black;
                background-color: $color-white;
                transition: all 0.3s ease 0s;
                &:hover {
                    background-color: $color-blue;
                }
            }
            .menu__cabinet {
                display: inline-flex;
                padding: 0 20px;
                background-color: $color-white;
                border: 1px solid $color-gray;
            }
            .menu__item {
                padding: 0 15px;
            }
        }
        @media (min-width: 768px) {
            &:not(.menu__list_active) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 85px;
            }
        }
    }
    // .menu__item
    &__item {
        // padding: 0 15px;
        a {
            white-space: nowrap;
        }
    }
    // .menu__logo
    &__logo {
        img {
            width: 125px;
            height: 55px;
        }
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }
    // .menu__description
    &__description {
        max-width: 245px;
        font-family: $font-family_Lato;
        font-size: 13px;
        line-height: 17 / 13 * 100%;
        color: #959595;
    }
    // .menu__search {
    &__search {
        display: none;
        width: 100%;
        margin-bottom: 25px;
        @media (min-width: 768px) {
            max-width: 330px;
        }
    }
    // .menu__search-label
    &__search-label {
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        height: 42px;
        width: 100%;
        &:before {
            position: absolute;
            right: 15px;
            color: #676767;
        }
        button {
            border: 1px solid #888888;
            border-left: none;
            height: 42px;
            padding-right: 15px;
            &:before {
                color: #676767;
            }
        }
    }
    // .menu__search-line
    &__search-line {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        font-family: $font-family_Montserrat;
        font-size: 12px;
        line-height: 15 / 12 * 100%;
        letter-spacing: 0.6px;
        color: #676767;
        border: 1px solid #888888;
        border-right: none;
    }
    // .menu__number
    &__number {
        font-family: $font-family_Montserrat;
        font-size: 16px;
        line-height: 20 / 16 * 100%;
        font-weight: 600;
        letter-spacing: 0.8px;
        color: #dfdfdf;
        @media (max-width: 767px) {
            display: none;
        }

        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
    // .menu__btn
    &__btn {
        @media (max-width: 1139px) {
            display: none;
        }
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        letter-spacing: 0.8px;
        border-radius: 21px;
        @media (min-width: 1140px) {
            height: 32px;
            font-size: 12px;
            line-height: 15 / 12 * 100%;
            letter-spacing: 0.6px;
        }
    }
    // .menu__cabinet
    &__cabinet {
        transition: all 0.3s ease 0s;
        span {
            margin-right: 5px;
        }
        &:hover {
            color: $color-white;
            background-color: $color-gray;
        }

        @media (max-width: 1139px) {
            margin-bottom: 15px;
        }
        @media (min-width: 1140px) {
            display: inline-flex;
            padding: 0 20px;
            background-color: $color-white;
            color: $color-black;
        }
    }
    // .menu__dealer
    &__dealer {
        border: 1px solid $color-blue-dark;
        @media (max-width: 1139px) {
            margin-bottom: 40px;
        }
        padding: 0 25px;
        color: $color-white;
        background-color: $color-blue-dark;

        transition: all 0.3s ease 0s;
        &:hover {
            color: $color-white;
            background-color: transparent;
        }
    }
    // .menu__languages
    &__languages {
        display: none;
        @media (min-width: 1140px) {
            display: flex;
            align-items: center;
        }
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: transparent;
        }
    }
    // .menu__lang
    &__lang {
        margin-right: 55px;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        letter-spacing: 0.7px;
        color: #888888;
        @media (min-width: 1140px) {
            margin-right: 40px;
            font-size: 12px;
            line-height: 15 / 12 * 100%;
            letter-spacing: 0.6px;
            color: #aaaaaa;
        }
        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
        @media (min-width: 1140px) {
            margin-right: 15px;
        }
        &:last-child {
            margin-right: 0;
        }
        // .menu__lang_current
        &_current {
            color: #0e0e0e;
            border-bottom: 2px solid #0e0e0e;
            transition: all 0.3s ease 0s;
            &:hover {
                text-decoration: none;
            }

            @media (min-width: 1140px) {
                color: #ffffff;
                border-bottom: 2px solid #fff;
            }
        }
    }
}
