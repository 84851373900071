.cards {
    // .cards__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 70px;
        @media (min-width: 768px) {
            padding: 0 40px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 95px;
        }
    }
    // .cards__row
    &__row {
        display: flex;
        flex-direction: column;
        @media (max-width: 1139) {
            align-items: center;
            justify-content: center;
        }
        @media (min-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -15px;
        }
        @media (min-width: 1140px) {
            margin: 0 -30px;
        }
    }
    // .cards__column
    &__column {
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            padding: 0 15px;
            flex: 0 0 50%;
        }
        @media (min-width: 1140px) {
            padding: 0 30px;
            flex: 0 0 33.333%;
        }
    }
}

.card-item {
    // .card-item__img
    &__img {
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
        img {
            max-height: 100%;
        }
    }
    // .card-item__title
    &__title {
        margin-bottom: 25px;
        font-family: $font-family_Montserrat;
        font-size: 18px;
        line-height: 22 / 18 * 100%;
        font-weight: 600;
        color: $color-blue-dark;
        a {
            transition: all 0.3s ease 0s;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    // .card-item__description
    &__description {
        flex: 1 1 auto;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            margin-bottom: 20px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 30px;
        }
        font-family: $font-family_Lato;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
        color: #606060;
    }
    // .card-item__side
    &__side {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        @media (min-width: 768px) {
            margin-bottom: 50px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 80px;
        }
    }
    // .card-item__more
    &__more {
        position: relative;
        display: inline-block;
        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 22 / 14 * 100%;
        font-weight: 600;
        color: #010100;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 15px;
            width: 100%;
            height: 2px;
            background: $color-blue-dark;
            transition: all 0.3s ease 0s;
        }
        &:hover {
            &:after {
                left: 0;
            }
        }
    }
    // .card-item__date
    &__date {
        font-size: 14px;
        color: $color-gray;
    }
}
