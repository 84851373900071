.page404 {
    padding-top: 200px;
    padding-bottom: 200px;
    background: no-repeat center / cover url('/static/images/bg-404.png');
    // .page404__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        @media(max-width:767px) {
            text-align: center;
        }
    }
    // .page404__title
    &__title {
        margin-bottom: 45px;
        font-family: $font-family_Montserrat;
        font-weight: 700;
        font-size: 120px;
        line-height: calc(176 / 144 * 100%);
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #021632;
        @media(min-width:768px) {
            font-size: 144px;
        }
    }
    // .page404__subtitle
    &__subtitle {
        margin-bottom: 45px;
        font-family: $font-family_Lato;
        font-size: 48px;
        line-height: calc(57 / 48 * 100%);
        font-weight: 700;
        letter-spacing: 1px;
        color: $color-red;
    }
    // .page404__text
    &__text {
        margin-bottom: 50px;
        font-family: $font-family_Montserrat;
        font-size: 24px;
        line-height: calc(29 / 24 * 100%);
        font-weight: 700;
        letter-spacing: 1px;
        color: $color-gray;
    }
    // .page404__btn
    &__btn {
        @extend .btn;
        border-radius: 26px;
        text-transform: uppercase;
    }
}
