.en-contacts {
    // Процентный блок для контента который выходит за рамки ограничивающего контейнера
    // Ширина контента/картинки в процентах (соблюдайте область видимости)
    --image-percentage: 0.4;
    --content-percentage: 0.5;

    // Блок для смены позиционирования
    @media(min-width:768px) {
        display: flex;
        flex-direction: row-reverse;
    }
    // .en-contacts__content
    &__content {
        flex: 0 0 100%;
        display: flex;
    }
    // .en-contacts__wrap
    &__wrap {
        @include x-container();
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-top: 65px;
        padding-bottom: 65px;
        z-index: 2;
    }
    // .en-contacts__h2
    &__h2 {
        @extend .brands__title;
        @media(max-width:767px) {
            color: $color-black;
            margin-bottom: 70px;
        }
        @media(min-width:768px) {
            position: absolute;
        }
    }
    // Для блока с "контеном" поиты при смене позиционирования
    // .en-contacts__body
    &__body {
        display: flex;
        flex-direction: column;
        a {
            transition: all 0.3s ease-in;
            &:hover {
                color: $color-red;
            }
        }
        @media(min-width:768px) {
            width: calc((var(--content-percentage) * 100%));
            padding-left: var(--padding);
            margin-left: auto;
        }
    }
    // .en-contacts__lets

    &__lets{
        position: absolute;

        @media(max-width:768px) {
            display: none;
        }

        //svg {
        //    flex: 0 0 34px;
        //    margin-right: 20px;
        //    color: $color-blue-en;
        //}
    }

    // .en-contacts__item
    &__item {
        display: flex;
        svg {
            flex: 0 0 34px;
            margin-right: 20px;
            color: $color-blue-en;
        }
        blue {
            display: inline;
            color: $color-blue-en;
        }
        span {
            font-size: 18px;
            font-weight: 500;
            line-height: calc(30 / 18 * 100%);
        }
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    // Для блока с "картинкой" поиты при смене позиционирования, и при смене ширины контейнера
    // .en-contacts__image
    &__image {
        position: relative;
        background-color: #293240;
        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @media(min-width:768px) {
            // flex: 0 0 calc((100vw - (var(--padding) * 2)) * var(--image-percentage) + var(--padding));
            flex: 0 0 calc(100vw * var(--image-percentage) + (var(--padding) * 2));
            transform: translateX(100%);
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
        @media(min-width:1200px) {
            flex: 0 0 calc(50vw - (var(--content-percentage) - 0.5) * (var(--wrap) - var(--padding)));
        }
        // @media(min-width:1920px) {
        //     flex: 0 0 calc(50vw - (var(--content-percentage) - 0.5) * (var(--wrap) - var(--padding)));
        // }
    }
}
