.petition {
    position: relative;
    // .petition__bg
    &__bg {
        display: block;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // .petition__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
        z-index: 2;
        @media (min-width: 768px) {
            background: transparent;
            height: 460px;
            padding-top: 0;
            padding-bottom: 0;
        }
        @media (min-width: 1140px) {
            height: 500px;
        }
    }
    // .petition__container
    &__container {
        text-align: center;
        background: rgba(0, 0, 0, 0.75);
        padding: 50px 15px;
        margin: 0 -15px;

        @media (min-width: 768px) {
            position: relative;
            margin: 0;
            max-height: 415px;
            max-width: 450px;
            padding: 70px 40px;
            transform-style: preserve-3d;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #979797;
                transform: translateZ(-1px) translate(-5px, 5px);
            }
        }
    }
    // .petition__h2
    &__h2 {
        font-weight: 500;
        margin-bottom: 25px;
        color: $color-white;
    }
    // .petition__description
    &__description {
        margin-bottom: 35px;
        color: $color-white;
        @media (min-width: 768px) {
            margin-bottom: 45px;
        }
    }
    // .petition__form
    &__form {
        display: flex;
        flex-direction: column;
    }
    // .petition__field
    &__field {
        @include form-field;
        input {
            background-color: $color-white;
        }
        color: $color-white;
        margin-bottom: 20px;
    }
    // .petition__btn
    &__btn {
        @include btn();
        align-self: center;
    }
}
