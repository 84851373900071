.b-banner {
    // .b-banner__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .b-banner__link-box
    &__link-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -13px;
        overflow: hidden;
        @media(min-width:1440px) {
            margin: 0 -30px;
        }
    }
    // .b-banner__link
    &__link {
        display: inline-flex;
        align-items: center;
        margin-bottom: 25px;
        padding: 0 13px;
        svg {
            transition: all 0.3s ease-in;
            margin-right: 10px;
            color: $color-black;
        }
        span {
            transition: all 0.3s ease-in;
            border-bottom: 2px dashed $color-gray;
        }
        &:hover {
            svg {
                color: $color-gray;
            }
            span {
                color: $color-gray;
                border-bottom-style: solid;
            }
        }
        @media(min-width:1440px) {
            padding: 0 30px;
        }
    }
    // .b-banner__bg
    &__bg {
        position: relative;
        min-height: 210px;
        picture {
            display: block;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media(min-width:768px) {
            min-height: 330px;
        }
        @media(min-width:1920px) {
            padding-bottom: 24%;
        }
    }
}
