.en-uofs {
    position: relative;
    padding: 50px 0;
    z-index: 1;
    &::after {
        //content: '';
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
        //background-color: rgba($color: $color-white, $alpha: 0.7);
    }
    @media(min-width:768px) {
        padding: 100px 0;
    }
    // .en-uofs__logo
    &__logo{
        position: relative;
        text-align: center;
        margin-bottom: 70px;
    }
    // .en-uofs__bg
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover
    }
    // .en-uofs__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
        z-index: 2;
    }
    // .en-uofs__h2
    &__h2 {
        @extend .en-about__h2;
        color: $color-white;
        margin-bottom: 70px;

        &:after{
            background-color: $color-blue-light-en;
        }
    }
    // .en-uofs__text
    &__text {
        @extend .en-about__text;
        color: $color-white;
        margin-bottom: 70px;
    }
    // .en-uofs__row
    &__row {
    }
    // .en-uofs__item
    &__item {
        display: flex;
        align-items: center;
        line-height: 140%;
        letter-spacing: -0.65px;
        &:not(:last-child) {
            margin-bottom: 45px;
        }
        @media(max-width:768px) {
            flex-direction: column;
        }
    }
    // .en-uofs__number
    &__number {
        flex: 0 0 60px;
        position: relative;
        font-size: 48px;
        color: $color-blue-light-en;
        font-weight: 700;
    }
    // .en-uofs__info
    &__info {
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
        padding-left: 20px;
        color: $color-white;
        border-left: 2px solid $color-blue-light-en;
    }
}
