.search-page {
    padding: 50px 0;
    // .search-page__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .search-page__title
    &__title {
        @extend .h2;
        margin-bottom: 20px;
    }
    // .search-page__search
    &__search {
    }
    // .search-page__counter
    &__counter {
        font-size: 18px;
        margin-bottom: 30px;
    }
    // .search-page__results
    &__results {
    }
    // .search-page__list
    &__list {
    }
    // .search-page__item
    &__item {
        padding: 0 0 20px 20px;
        &:last-child {
            padding: 0 0 0 20px;
        }
    }
    // .search-page__link
    &__link {
        font-size: 20px;
        color: $color-gray;
        text-decoration: underline;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
        }
    }
}
.search-header {
    display: flex;
    margin-bottom: 10px;
    @include form-field;
    // .search-header__input
    &__input {
        font-size: 20px;
    }
    // .search-header__btn
    &__btn {
        padding: 0 20px;
        border: 1px solid $color-gray;
        transition: all 0.3s ease-in;
        &:hover {
            background-color: rgba($color: $color-gray, $alpha: 0.9);
        }
    }
}
