$color-black: #000000;
$color-dark: #010101;
$color-white: #ffffff;
$color-blue: #487ECF;
$color-blue-light: #537ECE;
$color-blue-dark: #2F66B8;
$color-blue-en: #2B68E4;
$color-blue-light-en: #78B1E7;
$color-red: #D7182A;
$color-gray: #3E474E;
$color-gray-semi-dark: #828282;
$color-gray-light: #e1e1e1;
$color-gray-dark: #1F292D;

// ################################################################################
// Css variables
// Padding, необходимый для контейнера
:root {
    --padding: 15px;
    --wrap: 1140px;
    @media (min-width: 768px) {
        --padding: 40px;
    }
}

// ################################################################################
// Контейнер (оболочка) - max-width и padding
@mixin x-container($max-width: var(--wrap), $padding: var(--padding)) {
    max-width: calc(#{$max-width} + (#{$padding} * 2));
    padding-left: #{$padding};
    padding-right: #{$padding};
}
@mixin image-shadow($color, $direction: 'right') {
    @media (min-width: 768px) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            @if($direction == 'right') {
                left: -5px;
                box-shadow: 127px 0px 76px -40px $color inset;
            } @else if ($direction == 'left') {
                left: 5px;
                box-shadow: -127px 0px 76px -40px $color inset;
            }
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }
}

@mixin form-agree {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #505050;
    a {
        text-decoration: underline;
    }
    span {
        &::after, &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
        }
        &:after {
            align-self: flex-start;
            margin-top: 3px;
            visibility: visible;
            border: 1px solid #606060;
        }
        &:before {
            align-self: flex-start;
            margin-top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    input:checked + span {
        &:after {
            content: "";
            border: 1px solid $color-gray;
        }
        &:before {
            visibility: visible;
            content: url("/static/images/contacts/arrow.svg");
            font-size: 10px;
            background-color: $color-gray;
            z-index: 5;
        }
    }
}
@mixin form-field($color: $color-gray) {
    input {
        height: 52px;
        width: 100%;
        padding: 0 15px;
        border: 1px solid $color;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        color: #606060;
    }
    ul {
        margin-top: 5px;
        li {
            margin-left: 10px;
        }
    }
}
@mixin form-checkbox {
    position: relative;
    align-self: flex-start;
    flex: 0 0 24px;
    height: 24px;
    margin-right: 20px;
    margin-top: 3px;
    visibility: hidden;
}

@mixin btn($color: $color-white, $background: $color-blue) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    min-width: 100%;
    color: $color;
    transition: all 0.3s ease 0s;
    padding: 15px 25px;
    background-color: $background;
    border: 1px solid transparent;
    font-family: $font-family_Montserrat;
    line-height: 17 / 14 * 100%;
    font-size: 14px;
    &:hover {
        background-color: transparent;
        border: 1px solid $background;
        color: $background;
        &:before {
            color: $background;
        }
    }
    @media (min-width: 768px) {
        min-width: 215px;
    }
    @media (min-width: 1140px) {
        min-width: 175px;
    }
}

@mixin index-title($color: $color-blue) {
    padding-left: 10px;
    border-left: 6px solid $color;
    font-family: $font-family_Montserrat;
    line-height: 140%;
    font-weight: 500;
    font-size: 20px;
    @media (min-width: 768px) {
        font-size: 22px;
    }
    @media (min-width: 1140px) {
        font-size: 24px;
    }
    a {
        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
}

.wrapper {
    max-width: 100%;
    min-width: 320px;
}
.h2 {
    font-family: $font-family_Montserrat;
    font-size: 22px;
    line-height: 140%;
    @media (min-width: 768px) {
        font-size: 24px;
    }
    @media (min-width: 1140px) {
        font-size: 26px;
    }
}

.text {
    font-family: $font-family_Lato;
    font-size: 16px;
    line-height: 140%;
    &_small {
        font-size: 14px;
        line-height: 17 / 14 * 100%;
    }
}
.btn,
.btn-shadow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    min-width: 100%;
    color: $color-white;
    transition: all 0.3s ease 0s;
    @media (min-width: 768px) {
        min-width: 215px;
    }
    @media (min-width: 1140px) {
        min-width: 175px;
    }
}
.btn {
    padding: 15px 25px;
    background-color: $color-blue;
    border: 1px solid transparent;
    font-family: $font-family_Montserrat;
    line-height: 17 / 14 * 100%;
    font-size: 14px;
    &:hover {
        color: $color-blue;
        background-color: transparent;
        border: 1px solid $color-blue;
        &:before {
            color: $color-blue;
        }
    }
}
.btn-shadow {
    padding: 0 40px;
    box-shadow: 5px 5px 0 0 $color-gray;
    background: $color-blue;
    &:hover {
        animation: runner 1.5s linear infinite normal running 0s none;
        background: $color-blue;
    }
    @keyframes runner {
        0% {
            box-shadow: 5px 5px 0 0 $color-gray;
        }
        25% {
            box-shadow: -5px 5px 0 0 $color-gray;
        }
        50% {
            box-shadow: -5px -5px 0 0 $color-gray;
        }
        75% {
            box-shadow: 5px -5px 0 0 $color-gray;
        }
        100% {
            box-shadow: 5px 5px 0 0 $color-gray;
        }
    }
}
.item {
    font-family: $font-family_Lato;
    font-size: 16px;
    line-height: 200%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    &:before {
        content: "";
        flex: 0 0 10px;
        align-self: flex-start;
        height: 10px;
        border-radius: 50%;
        margin-right: 15px;
        margin-top: 12px;
        background-color: $color-gray;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 6px;
}
.swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    background-color: #696969;
    border: 1px solid #fff;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background-color: #1f292d;
}
.index-title {
    padding-left: 10px;
    border-left: 6px solid $color-blue-dark;
    font-family: $font-family_Montserrat;
    line-height: 140%;
    font-weight: 500;
    font-size: 20px;
    @media (min-width: 768px) {
        font-size: 22px;
    }
    @media (min-width: 1140px) {
        font-size: 24px;
    }
    a {
        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
}

.ymaps-2-1-79-ground-pane {
    filter: grayscale(100%);
}

.decor-link {
    display: inline-block;
    position: relative;
    padding-bottom: 5px;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        width: 100%;
        height: 2px;
        background: $color-blue-en;
        transition: all 0.3s ease 0s;
    }
    &:hover {
        &:after {
            left: 0;
        }
    }
}

.admin {
    p {
        margin-bottom: 10px;
    }

    ul {
        margin: 20px 0;
        li {
            margin-left: 30px;
            list-style: disc;
        }
    }

    a {
        color: $color-gray;
        text-decoration: underline;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-red;
        }
    }
}
.show-hide-btn {
    font-weight: 700;
}
