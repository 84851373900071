.support {
    // .support__wrap
    &__wrap {
        @include x-container(915px);
        margin-top: 60px;
        margin-bottom: 60px;
        @media (min-width: 768px) {
            margin-bottom: 65px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 80px;
        }
    }
    // .support__card
    &__card {
        margin-bottom: 60px;
        @media (min-width: 768px) {
            margin-bottom: 65px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 80px;
        }
    }
    // .support__title
    &__title {
        margin-bottom: 25px;
        font-family: $font-family_Montserrat;
        font-size: 20px;
        line-height: 140%;
        font-weight: 500;
        text-align: center;
        @media (min-width: 768px) {
            font-size: 24px;
            margin-bottom: 25px;
        }
        // .support__text
        &__text {
            font-family: $font-family_Lato;
            font-size: 14px;
            line-height: 20 / 14 * 100%;
        }
    }
}

// .swiper-container-service-support.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
// 	margin: 0 4px;
// }
// .swiper-container-service-support .swiper-pagination-bullet {
// 	width: 10px;
// 	height: 10px;
// }
