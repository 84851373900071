.filter {
    &__item {
        position: relative;
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
    // .filter__search
    &__search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        font-family: $font-family_Montserrat;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        background-color: transparent;
        svg {
            cursor: pointer;
            transition: all 0.3s ease 0s;
        }
        input {
            display: inline-block;
        }
        &_active {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    // .filter__result
    &__result {
        display: none;
        &_active {
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            border: 1px solid $color-gray;
            background-color: $color-white;
            z-index: 20;
        }
    }
    // .filter__empty
    &__empty {
        font-family: $font-family_Montserrat;
        font-size: 20px;
        line-height: 200%;
        padding: 0 20px;
    }
    // .filter__submit
    &__submit {
        @extend .btn;
        width: 100%;
    }
}
