.faq {
    &__title {
        @extend .h2;
        margin-bottom: 30px;
        text-align: center;
    }
    &__content {
        display: flex;
        flex-direction: column;
        max-width: 900px;
        padding: 0 15px;
        margin: 0 auto 80px;
        a {
            margin-bottom: 10px;
            color: $color-gray;
            transition: all 0.3s ease-in;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__ansver {
        display: none;
        margin-bottom: 30px;
        &._active {
            display: block;
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
        }
        ul {
            margin-left: 30px;
            li {
                list-style: disc;
                margin-left: 10px;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
