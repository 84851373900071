@import "../filter-sity";
@import "../filter-brand";

main.service {
    .filter {
        // .filter__wrap
        &__wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            @include x-container(780px);
            margin: 0 auto 45px;
            @media (min-width: 768px) {
                flex-direction: row;
            }
            @media (min-width: 1140px) {
                margin-bottom: 50px;
            }
        }
        // .filter__item
        &__item {
            width: 100%;
            margin-bottom: 25px;

            @media (min-width: 768px) {
                flex-direction: column;
                margin-bottom: 0;
                margin-right: 30px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media (min-width: 1140px) {
                flex-direction: row;
            }
        }
        // .filter__name
        &__name {
            font-family: $font-family_Montserrat;
            font-size: 16px;
            line-height: 20 / 16 * 100%;
            white-space: nowrap;
            margin-bottom: 20px;
            @media (min-width: 768px) {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media (min-width: 1140px) {
                margin-bottom: 0;
            }
        }
        // .filter__search
        &__search {
            min-width: 270px;
            padding: 0 20px;

            color: $color-gray;
            border: 1px solid $color-gray;
            input {
                display: inline-block;
                width: 100%;
                padding: 17px 0px;
            }
        }
        // .filter__result
        &__result {
            &_active {
                top: 100px;
                @media (min-width: 1140px) {
                    top: 60px;
                }
            }
        }
    }
}
