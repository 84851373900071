.form {
    // .form__wrap
    &__wrap {
        @include x-container();
        position: relative;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 65px;
        @media (min-width: 768px) {
            padding-bottom: 70px;
        }
        @media (min-width: 1140px) {
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }
    // .form__subtitle
    &__subtitle {
        font-family: $font-family_Montserrat;
        font-size: 24px;
        line-height: 31 / 24 * 100%;
        text-align: center;
        margin-bottom: 30px;
    }
    &__success {
        display: none;
    }
    &.subscribe {
        .footer__social-link-box {
            justify-content: center;
            margin-bottom: 30px;
            .footer__social-link {
                color: $color-black;
            }
        }
        .letter-form__item {
            flex: 1 1 100%;
        }
    }
}

.letter-form {

    .required.errors{

        li{
            margin-left: 0px;
            font-size: 14px;
            color: $color-red;
        }
    }

    &.success + .form__success {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 2rem;
        z-index: 5;
        background-color: $color-white;
    }
    // .letter__body
    &__body {
        display: flex;
        margin: 0 -10px 20px;
        @media (max-width: 767px) {
            flex-direction: column;
            margin: 0px 0px 20px 0px;
        }

        &_margin{
            margin-bottom: 60px;
        }
    }
    &__item {
        flex: 0 1 50%;
        padding: 0 10px;
        @media (max-width: 767px) {
            flex-direction: column;
            padding: 0;
        }
    }
    // .letter__info
    &__info {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
            margin-bottom: 10px;
        }
    }
    // .letter__radio
    &__radio {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
            margin-bottom: 10px;
        }

        ul{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: flex-start;

            li{
                margin-bottom: 14px;
                height: 52px;

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                align-content: center;

                label{
                    margin-left: 10px;
                    cursor: pointer;
                }
                input{
                    -ms-transform: scale(1.5); /* IE 9 */
                    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
                    transform: scale(1.5);
                    cursor: pointer;
                }
            }
        }
    }
    // .letter__field
    &__field {
        @include form-field;
        &:not(:last-child) {
            margin-bottom: 12px;
        }

        h2{
            font-size: 16px;
            font-weight: bold;
            color: $color-black;
        }
    }
    // .letter__area
    &__area {
        color: #606060;
    }
    // .letter__textarea
    &__textarea {
        width: 100%;
        height: 100%;
        textarea {
            border: 1px solid $color-gray;
            padding: 20px 15px;
            width: 100%;
            height: 100%;
            resize: none;
            cursor: auto;
            @media (max-width: 767px) {
                min-height: 100px;
            }
        }
        ul {
            li {
                margin-left: 10px;
            }
        }
    }
    // .letter__label
    &__label {
        @include form-agree;
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 20 / 14 * 100%;
    }
    // .letter__checkbox
    &__checkbox {
        @include form-checkbox;
    }
    &__btn-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // .letter__btn
    &__btn {
        @extend .btn;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}
