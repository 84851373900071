.team {
    background: #1c1d1f;
    // .team__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    // .team__title
    &__title {
        @extend .h2;
        margin-bottom: 30px;
        color: $color-white;
    }
    // .team__row
    &__row {
        display: flex;
        @media (max-width: 1199px) {
            flex-direction: column;
        }
    }
    // .team__column
    &__column {
        min-width: 175px;
        flex: 0 0 50%;
    }
    // .team__item
    &__item {
        height: 100%;
        width: 100%;
    }
    &__body {
        margin-top: 20px;
        margin-bottom: 90px;
        margin-left: 120px;
        @media (max-width: 1919px) {
            margin-top: calc(
                30px + (20 - 30) * ((100vw - 320px) / (1920 - 320))
            );
            margin-bottom: calc(
                40px + (90 - 40) * ((100vw - 320px) / (1920 - 320))
            );
            margin-left: calc(
                0px + (120 - 0) * ((100vw - 1000px) / (1920 - 1000))
            );
        }
        @media (max-width: 1199px) {
            margin-left: 0;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .team__text
    &__text {
        margin-bottom: 30px;
        color: $color-white;
    }
    // .team__btn
    &__btn {
        @extend .btn;
        margin-bottom: 20px;
    }
}

.f-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // .f-row__column
    &__column {
        width: 100%;
        margin-bottom: 20px;
        flex: 0 0 290px;
        @media (min-width: 500px) {
            flex: 0 0 215px;
        }
        @media (min-width: 1200px) {
            flex: 0 0 175px;
        }
        // @media (min-width: 769px) {
        //   flex: 0 1 33.333%;
        //   padding: 0 10px;
        // }
    }
    // .f-row__item
    &__item {
        position: relative;
        overflow: hidden;
        // height: 220px;
        height: 380px;
        @media (min-width: 500px) {
            height: 268px;

            &:hover {
                // not like cursor pointer
                .f-row__info {
                    background: #00000067;
                    justify-content: flex-end;
                    height: 100%;
                    .f-row__content:first-child {
                        margin-bottom: 5px;
                    }
                }
                .f-row__link {
                    display: flex;
                }
            }
        }
        @media (min-width: 1200px) {
            height: 220px;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }
    // .f-row__info
    &__info {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 5px;
        width: 100%;
        height: 130px;
        background: #565656;
        text-align: center;
        @media (min-width: 500px) {
            justify-content: flex-start;
            height: 80px;
            transition: all 0.3s ease 0s;
        }
    }
    &__content {
        @media (max-width: 767px) {
            margin-bottom: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    // .f-row__name
    &__name {
        margin-bottom: 10px;
        // font-size: 18px;
        // line-height: 22 / 18 * 100%;
        // @media (min-width: 600px) {
        font-size: 14px;
        font-weight: bold;
        line-height: 17 / 14 * 100%;
        // }
        color: $color-white;
    }
    // .f-row__status
    &__status {
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        @media (min-width: 600px) {
            font-size: 12px;
            line-height: 15 / 12 * 100%;
        }
        color: $color-white;
        text-align: center;
    }
    // .f-row__link
    &__link {
        display: flex;
        align-items: center;
        font-weight: 500;
        // font-size: 16px;
        line-height: 22 / 12 * 100%;
        color: $color-white;
        transition: all 0.3s ease 0s;
        // @media (min-width: 600px) {
        font-size: 12px;
        // }
        @media (min-width: 500px) {
            display: none;
        }
        &:before {
            margin-right: 5px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &__tel, &__email {
        svg {
            margin-right: 5px;
        }
    }
}
// Swiper slider

.swiper-container.swiper-container-about {
    padding-bottom: 30px;
}

// .swiper-container.swiper-container-about.swiper-container-multirow-column > .swiper-wrapper {
//     margin: 0 auto;
//     flex-direction: row;
// }
