.header {
    position: relative;
    z-index: 3;
    margin-bottom: -5px;
    // .header__top
    &__top {
    }
    // .header__bottom
    &__bottom {
        display: block;
        @media (max-width: 1139px) {
            display: none;
        }
    }
}

.top-header {
    background: #0e0e0e;
    // .top-header__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
}

.bottom-header {
    background: $color-gray;
    // .header__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .bottom-header__menu
    &__menu {
        display: flex;
        align-items: center;
    }
    // .bottom-header__search
    &__search {
        position: relative;
        display: flex;
        height: 45px;
        flex: 0 0 14px;
        padding: 0 15px;
        margin-right: 35px;
        transition: all 0.3s ease 0s;
        &_active {
            background-color: red;
            z-index: 20;
            &:before {
                position: absolute;
                content: "";
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-top: 5px solid red;
                border-bottom: none;
                z-index: 10;
            }
        }
    }
    // .bottom-header__search-btn
    &__search-btn {
        color: $color-white;
    }
    // .bottom-header__search-box
    &__search-box {
        display: none;
        &_active {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 45px;
            left: -15px;
            width: 300px;
            background: #0e0e0e;
            padding: 25px 40px;
        }
        input {
            width: 100%;
            height: 30px;
            padding: 0 10px;
            background-color: $color-white;
        }
        button {
            background-color: $color-white;
            padding-right: 10px;
            &:before {
                transition: all 0.3s ease 0s;
                color: $color-gray;
            }
            &:hover {
                &:before {
                    color: $color-gray;
                }
            }
        }
    }
    // .bottom-header__list
    &__list {
        @media (max-width: 1139px) {
            display: none;
            text-align: right;
            width: 100%;
            background: #3e474e;
            padding: 40px 15px 50px;
        }
        @media (min-width: 1140px) {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
        }
    }
    // .bottom-header__item
    &__item {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        @media (min-width: 1140px) {
            margin-bottom: 0;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    // .bottom-header__link
    &__link {
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        letter-spacing: 0.7px;
        color: $color-white;

        transition: all 0.3s ease 0s;
        &:hover {
            text-decoration: underline;
        }
    }
}

@import "./menu";
