.en-partner {
    padding: 100px 0;
    // .en-partner__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .en-partner__h2
    &__h2 {
        @extend .en-about__h2;
    }
    // .en-partner__text
    &__text {
        @extend .en-about__text;
        @media(min-width:768px) {
            margin-bottom: 60px;
        }
    }
    // .en-partner__content
    &__content {
        margin-bottom: 50px;
        padding: 60px 40px 45px;
        border-radius: 20px;
        background-color: #1F292D;
        color: $color-white;
    }
    // .en-partner__info
    &__info {
        margin-bottom: 50px;
        @media(min-width:768px) {
            display: flex;
        }
    }
    // .en-partner__image
    &__image {
        @media(max-width:767px) {
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            margin-right: 40px;
            flex: 0 0 271px;
        }
    }
    // .en-partner__description
    &__description {
        font-size: 20px;
        font-weight: 500;
        line-height: calc(40 / 20 * 100%);
    }
    // .en-partner__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        @media(min-width:768px) {
            margin: 0 -10px;
            overflow: hidden;
        }
    }
    // .en-partner__item
    &__item {
        flex: 1 1 100%;
        margin-bottom: 20px;
        display: inline-flex;
        align-items: center;
        transition: all 0.3s ease-in;
        svg {
            flex: 0 0 37px;
            margin-right: 20px;
            @media(max-width:374px) {
                display: none;
            }
        }
        span {
            @extend .en-about__link;
            white-space: nowrap;
            @media(max-width:499px) {
                font-size: 14px;
            }
        }
        &:hover {
            color: rgba($color: $color-blue-en, $alpha: 0.7);
        }
        @media(min-width:768px) {
            flex: 1 0 auto;
            padding: 0 10px;
        }
    }
    // .en-partner__advantages
    &__advantages {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        .slide-item {
            flex: 1 1 100%;
            margin-bottom: 70px;
            padding: 0 10px;
            @media(min-width:375px) {
                flex: 1 1 calc(100% / 2);
            }
            @media(min-width:800px) {
                flex: 1 1 calc(100% / 4);
            }
            // .slide-item__image
            &__image {
            }
            // .slide-item__text
            &__text {
            }
        }
    }
}
