.b-video {
    padding: 50px 0;
    // .b-video__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .b-video__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 30px;
        text-align: center;
    }
    // .b-video__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        overflow: hidden;
    }
    // .b-video__column
    &__column {
        flex: 0 1 100%;
        padding: 0 20px;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            flex: 0 1 calc(100% / 2);
        }
        @media(min-width:1440px) {
            flex: 0 1 calc(100% / 3);
        }
    }
    // .b-video__item
    &__item {
        position: relative;
        // 16:9 aspect radio
        padding-bottom: 56.25%;
        margin-bottom: 20px;
        overflow: hidden;
    }
    // .b-video__item-safe
    &__item-safe {
        @extend .b-video__item;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.item-video {
    // .item-video__poster
    &__poster, &__poster img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
        cursor: pointer;
        &:hover {
            .item-video__poster-btn {
                color:rgba(0, 0, 0, 0.25)
            }
        }
    }
    // .item-video__poster-btn
    &__poster-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 35px;
        background: transparent;
        z-index: 3;
        color: #f00;
        transition: color 0.3s ease;
        .svg-icon {
            width: 100%;
            height: 100%;
        }
        @media(min-width:768px) {
            width: 80px;
            height: 55px;
        }
    }
    // .item-video__video
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
}
