@import "filter-common";
.filter {
    &__brand-box {
        @extend .filter__sity-box;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
    }
    &__brand-name {
        @include form-agree;
        align-items: center;
        flex: 0 1 100%;
        margin-bottom: 10px;
        padding-right: 10px;
        span {
            pointer-events: none;
        }
        @media (min-width: 768px) {
            flex: 0 1 50%;
        }
    }

    &__brand {
        @include form-checkbox;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        margin-right: 10px;
    }
}
