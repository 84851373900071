.en-dealership {
    padding: 50px 0;
    @media(min-width:768px) {
        padding: 100px 0;
    }
    // .en-dealership__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        overflow: hidden;
    }
    // .en-dealership__h2
    &__h2 {
        @extend .en-about__h2;
        margin-bottom: 50px;
    }
    // .en-dealership__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -35px;
    }
    // .en-dealership__item
    &__item {
        flex: 1 1 100%;
        padding: 0 35px;
        margin-bottom: 40px;
        .slide-item__image {
            display: flex;
            align-items: flex-end;
            height: 145px;
            @media(max-width:600px) {
                justify-content: center;
            }
        }
        .slide-item__text {}
        @media(min-width:600px) {
            flex: 1 0 calc(100% / 2);
        }
        @media(min-width:900px) {
            flex: 1 0 calc(100% / 3);
        }
    }
    // .en-dealership__advantages
    &__advantages {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(273px, 1fr));
        justify-items: center;
        grid-row-gap: 50px;
    }
}
