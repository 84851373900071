.en-supplier {
    padding: 50px 0;
    @media(min-width:768px) {
        padding: 100px 0;
    }
    // .en-supplier__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        overflow: hidden
    }
    // .en-supplier__h2
    &__h2 {
        @extend .en-about__h2;
        margin-bottom: 70px;
    }
    // .en-supplier__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -35px;
    }
    // .en-supplier__item
    &__item {
        flex: 1 1 100%;
        padding: 0 35px;
        margin-bottom: 85px;
        display: flex;
        @media(min-width:600px) {
            flex: 1 0 calc(100% / 2);
        }
        @media(max-width:899px) {
            flex-direction: column;
            text-align: center;
        }
        @media(min-width:900px) {
            align-items: flex-end;
        }
    }
    // .en-supplier__image
    &__image {
        margin-right: 20px;
        @media(max-width:899px) {
            margin-bottom: 20px;
        }
    }
    // .en-supplier__over
    &__over {
        margin-right: 20px;
        color: $color-blue-en;
        @media(max-width:899px) {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 10px;
        }
    }
    // .en-supplier__over-prefix
    &__over-prefix {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
    }
    // .en-supplier__over-suffix
    &__over-suffix {
        font-weight: 500;
        font-size: 48px;
        line-height: calc(59 / 48 * 100%);
    }
    // .en-supplier__text
    &__text {
        font-weight: 500;
        font-size: 24px;
        line-height: calc(29 / 24 * 100%);
    }
    // .en-supplier__link-box
    &__link-box {
        text-align: center;
    }
    // .en-supplier__link
    &__link {
        @extend .en-about__link;
    }
}
