@import "../filter-brand";

main.news,
main.catalog {
    .filter {
        // .filter__wrap
        &__wrap {
            @include x-container();
            margin: 0 auto;
        }
        // .filter__row
        &__row {
            display: flex;
            flex-direction: column;
            @media (min-width: 1140px) {
                flex-direction: row;
            }
        }
        // .filter__column
        &__column {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 40px;
                overflow: hidden;
            }
            @media (min-width: 768px) {
                margin-right: 30px;
            }
            @media (min-width: 1140px) {
                margin-bottom: 0;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
            &:first-child {
                max-width: 390px;
            }
        }
        // .filter__item
        &__item {
            flex: 0 1 50%;
            display: flex;
            flex-direction: column;
            width: 100%;
            @media (min-width: 768px) {
                align-items: center;
                flex-direction: row;
                margin-right: 30px;
                margin-bottom: 0;
            }
        }
        // .filter__name
        &__name {
            font-family: $font-family_Montserrat;
            font-size: 16px;
            line-height: 20 / 16 * 100%;
            white-space: nowrap;
            margin-bottom: 20px;
            @media (min-width: 768px) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }
        // .filter__search
        &__search {
            min-width: 270px;
            padding: 0 20px;

            color: $color-gray;
            border: 1px solid $color-gray;
            input {
                display: inline-block;
                width: 100%;
                padding: 17px 0;
            }
        }
        // .filter__result
        &__result {
            padding: 20px;
            box-shadow: 5px 5px 0 0 $color-gray;
            &_active {
                top: 100px;
                @media (min-width: 768px) {
                    top: 60px;
                }
            }
        }
        &__btn-container {
            width: 100%;
            overflow: hidden;
        }
        &__btn-box {
            display: flex;
            overflow-x: auto;
            width: 100%;
        }
        // .filter__btn
        &__btn {
            @include btn();
            font-family: $font-family_Montserrat;
            font-size: 14px;
            line-height: 17 / 14 * 100%;
            background-color: transparent;
            border: 1px solid $color-gray;
            color: $color-gray;
            margin-right: 13px;
            min-width: auto;
            @media (min-width: 768px) {
                min-width: auto;
            }
            @media (min-width: 1140px) {
                min-width: auto;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background-color: $color-gray;
                color: $color-white;
            }
        }

        &__checked{
            background-color: $color-gray;
            color: $color-white;
            border: 1px solid $color-gray;
        }
    }
    .empty {
        @include x-container();
        margin: 0 auto;
        text-align: center;
        p {
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 30px;
        }
        a {
            @extend .btn;
        }
    }
}
