.warning {
    // .warning__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto 80px;
        @media (min-width: 768px) {
            margin-bottom: 60px;
        }
    }
    // .warning__h2
    &__h2 {
        margin-bottom: 12px;
        font-weight: 500;
        @media (min-width: 768px) {
            margin-bottom: 19px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 27px;
        }
    }
    &__ul {
        margin-bottom: 25px;
        li {
            &:before {
                content: "-";
                margin-right: 5px;
            }
        }
    }
    // .warning__text
    &__text {
    }
}
