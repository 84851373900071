.brands {
    background-color: #1f292d;
    // .brands__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
        overflow: hidden;
        padding-top: 55px;
        padding-bottom: 55px;
        @media (min-width: 1140px) {
            padding-top: 100px;
        }
    }
    // .brands__header
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        @media (min-width: 768px) {
            margin-bottom: 45px;
        }
        @media (min-width: 1140px) {
            margin-bottom: 55px;
        }
    }
    // .brands__text
    &__text {
        margin-bottom: 25px;
        font-family: $font-family_Lato;
        font-size: 17px;
        line-height: 140%;
        color: $color-white;
        @media (min-width: 768px) {
            margin-bottom: 50px;
        }
    }
    // .brands__title
    &__title {
        @extend .index-title;
        color: $color-white;
    }
    // .brands__btn
    &__btn {
        @include btn();
        min-width: 160px;
        @media (min-width: 768px) {
            min-width: 210px;
        }
        @media (min-width: 1140px) {
            display: none;
        }
    }
    // .brands__column
    &__column {
        // flex: 0 0 16.666%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        img {
            max-height: 80px;
            max-width: 100%;
        }
    }
    // .brands__item
    &__item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 120px;
        @media(min-width:600px) {
            max-width: 140px;
        }
    }
}

.swiper-container-index-brands {
    overflow: hidden;
}
.swiper-container-index-brands .swiper-wrapper {

}
.swiper-container-index-brands .swiper-wrapper .swiper-slide {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // background: red;
}
.swiper-container-index-brands.swiper-container-multirow-column
    > .swiper-wrapper {
    flex-direction: row;
    align-items: center;
}
.swiper-container-index-brands .swiper-wrapper {
    margin-bottom: 50px;
}
