.archive {
    // .archive__wrap
    &__wrap {
        @include x-container(760px);
        margin: 70px auto 75px;
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            margin-top: 55px;
            margin-bottom: 55px;
            flex-direction: row;
        }
    }
    // .archive__input
    &__input {
        height: 52px;
        padding: 0 20px;
        border: 1px solid #606060;
        font-size: 14px;
        line-height: 17 / 14 * 100%;
        color: #606060;
        z-index: 3;
        flex: 1 1 auto;
        @media (min-width: 768px) {
            margin-right: 20px;
        }
        @media (max-width: 767px) {
            margin-bottom: 25px;
        }
    }
    // .archive__btn
    &__btn {
        @extend .btn;
        min-width: 175px;
        display: flex;
        span {
            margin-right: 10px;
        }
    }
}
